import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import Footer from "./Footer";
import StepZero from "./steps/StepZero";
import StepOne from "./steps/StepOne";
import StepTwo from "./steps/StepTwo";
import "./style.css";
import Title from "./Title";
import StepThree from "./steps/StepThree";
import StepFour from "./steps/StepFour";
import { sendContent } from "../../api/sendContent";

export default function ProposeContent() {
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState(0);
  let location = useLocation();
  const [formContent, setFormContent] = useState({
    title: "",
    filetype: 0,
    thematic: [],
    file: {},
  });

  async function uploadFiles() {
    const bodyFormData = new FormData();
    bodyFormData.append(
      "json",
      JSON.stringify({
        title: formContent.title,
        fileType: formContent.filetype,
        mainThemeId: formContent.thematic,
      })
    );

    if (formContent.file.type === "file") {
      bodyFormData.append("attachment", formContent.file.content);
    } else {
      bodyFormData.append("link", formContent.file.content);
    }

    console.log("bodyFormData -> ", bodyFormData);

    await sendContent(bodyFormData);
  }

  useEffect(() => {
    if (step === 4) {
      uploadFiles();
    }
  }, [step]);

  useEffect(() => {
    if (location.state && location.state.proposeContentModal) {
      const { thematic_id, contentType } = location.state.proposeContentModal;
      setIsOpen(true);
      // setFormContent({
      //   title: "",
      //   filetype: contentType,
      //   thematic: [thematic_id],
      //   file: {},
      // });
      setStep(0);
    }
  }, [location]);

  const renderCurrentStep = (currentStep, step) => {
    if (currentStep !== step) return "d-none";
  };
  return (
    <React.Fragment>
      <button
        className="propose-content__button-mobile"
        onClick={() => setIsOpen(!isOpen)}
      >
        <i className="icon-write-bubble"></i>
      </button>
      <div className={`propose-content__window ${isOpen ? "active" : ""}`}>
        <Title openWindow={setIsOpen} windowStatus={isOpen} />
        <div
          className={`propose-content__main ${
            step === 3 ? "isFileUploadSection" : ""
          }`}
        >
          <div className={renderCurrentStep(step, 0)}>
            <StepZero />
          </div>
          <div className={renderCurrentStep(step, 1)}>
            <StepOne
              changeThemactic={setFormContent}
              currentForm={formContent}
            />
          </div>
          <div className={renderCurrentStep(step, 2)}>
            <StepTwo
              changeFileType={setFormContent}
              currentForm={formContent}
            />
          </div>
          <div className={renderCurrentStep(step, 3)}>
            <StepThree
              changeFilesList={setFormContent}
              currentForm={formContent}
            />
          </div>
          <div
            className={`propose-content__finish ${renderCurrentStep(step, 4)}`}
          >
            <StepFour
              openWindow={setIsOpen}
              windowStatus={isOpen}
              resetStep={setStep}
              resetContent={setFormContent}
            />
          </div>
        </div>
        <Footer
          changeStep={setStep}
          currentStep={step}
          currentForm={formContent}
        />
      </div>
    </React.Fragment>
  );
}
