import axios from "axios";
import filtersCompose from "../../helpers/FiltersCompose";
import getFormatedToken from "../../helpers/getFormatedToken";

export async function fetchOneContent(id) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/news/${id}/contents`,
      getFormatedToken()
    );
    const { data } = response.data;
    return data.newsContents[0].Content || {};
  } catch (error) {
    // TODO: Handle error properly
    // console.log(error);
  }
}
