import React, { useState, useEffect } from "react";
import "./style.css";
// api
import { fetchAllNews } from "../../../api/News/News";
import { fetchAllQuestions } from "../../../api/Questions/Questions";
import ItemListNews from "../../ContentListNews/ItemListNews";
import { Link } from "react-router-dom";

//components
import NotFound from "../../molecules/ErrorsMessages/NotFound";
import Item from "../../molecules/Questions/Item";
import CTAnotFound from "../../molecules/ErrorsMessages/CTAnotFound";
import { Slider } from "../../molecules/Slider/sliderWithCTA";
import { SwiperSlide } from "swiper/react";
import GradientCard from "../../molecules/Cards/Gradient";

export default function Informations({
  thematic_id,
  thematic_name,
  color,
  sub_theme,
  subthemes_of_maintheme,
}) {
  const [news, setNews] = useState([]);
  const [questions, setQuestions] = useState([]);
  async function getAllNewsWhereThematic() {
    if (sub_theme) {
      setNews(await fetchAllNews([`sub_theme:${sub_theme}`]));
    } else if (thematic_id) {
      setNews(await fetchAllNews([`main_theme:${thematic_id}`]));
    }
  }
  async function getAllQuestionsWhereThematic() {
    if (sub_theme) {
      setQuestions(await fetchAllQuestions([`sub_theme:${sub_theme}`]));
    } else if (thematic_id) {
      let temparray = [];
      for (const key in subthemes_of_maintheme) {
        let tempfetchquestion = await fetchAllQuestions([
          `sub_theme:${subthemes_of_maintheme[key]}`,
        ]);
        if (tempfetchquestion.length >= 1) {
          temparray.push(...tempfetchquestion);
        }
      }
      setQuestions(temparray);
    }
  }

  useEffect(() => {
    getAllQuestionsWhereThematic();
    getAllNewsWhereThematic();
  }, [subthemes_of_maintheme]);

  useEffect(() => {
    getAllQuestionsWhereThematic();
    getAllNewsWhereThematic();
  }, [sub_theme]);

  return (
    <div className="themactics__informations">
      <div>
        <h4>
          <i className="icon-question"></i> Questions RH
        </h4>

        {questions && questions.length ? (
          questions.map((item, index) => {
            return index < 3 && item && <Item data={item} />;
          })
        ) : (
          <CTAnotFound
            color={color}
            thematic_id={thematic_id}
            contentType={7}
          />
        )}

        {/* <div className="themactics__informations-questions_options">
          <Link to="/news">
            <i className="icon-options_horizontal"></i>
          </Link>
        </div> */}
      </div>
      <div className="themactics__informations-news">
        <h4>
          <i className="icon-news"></i> News {thematic_name}
        </h4>
        <div className="themactics__informations-articles">
          {news.length > 0 ? (
            news
              .filter(
                (article) => article.NewsContent[0].Content.state !== "DRAFT"
              )
              .map((item, index) => {
                return index < 2 && <ItemListNews item={item} key={index} />;
              })
          ) : (
            <CTAnotFound
              color={color}
              thematic_id={thematic_id}
              contentType={1}
            />
          )}
        </div>
        <div className="themactics__informations-articles_mobile">
          <Slider cta={{ text: "Toutes les news", path: "/news" }} title="">
            {news
              .sort(function (a, b) {
                return new Date(b.createdAt) - new Date(a.createdAt);
              })
              .map((item, index) => {
                return (
                  index < 3 && (
                    <SwiperSlide key={index}>
                      <Link to={`/news/${item.id}`}>
                        <GradientCard content={item} />
                      </Link>
                    </SwiperSlide>
                  )
                );
              })}
          </Slider>
        </div>

        <div className="themactics__informations-news_options">
          <Link to="/news">
            <i className="icon-options_horizontal"></i>
          </Link>
        </div>
      </div>
    </div>
  );
}
