import FilterForm from "../../components/Filter/FilterForm";
import ContentListNews from "../../components/ContentListNews/ContentListNews";
import React, { useContext, useEffect, useState } from "react";
import { EditMode } from "../../contexts/editmode";

export default function News() {
  const { editing } = useContext(EditMode);
  const [filters, setFilters] = useState([]);

  return (
    <React.Fragment>
      <FilterForm filtersCallback={setFilters} category={false} published={editing} />
      <ContentListNews filters={filters}></ContentListNews>
    </React.Fragment>
  );
}
