import React, { useState, useEffect, useContext } from "react";

import { Card, HrefBox } from "../../../components/molecules/AdminPanel/index";
// components
import CenterLayout from "../../../layouts/CenterLayout";
import FaqForm from "../Faq/FaqForm";
import ThematicsForm from "./ThematicsForm";
import QuestionPage from "../../Questions/QuestionPage";

export default function Thematics() {
  const [themeId, setThemeId] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [isValid, setisvalid] = useState(true);

  return (
    <CenterLayout title="Themes">
      <ThematicsForm getId={setThemeId} setisvalid={setisvalid} />
      {isValid && (
        <div
          className="thematic__faq-container"
          style={{
            display: "flex",
            alignItems: "flex-start",
            marginBottom: "20px",
            gap: "20px",
            maxWidth: "900px",
            margin: "auto",
          }}
        >
          {/* <div style={{ width: "70%" }}>
            <FaqForm filter={themeId} isValid={questions >= 3 ? false : true} />
          </div>
          <QuestionPage
            filter={[`main_theme:${themeId}`]}
            callback={setQuestions}
          /> */}
        </div>
      )}

      <HrefBox />
    </CenterLayout>
  );
}
