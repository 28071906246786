import React, { useState, useEffect } from "react";
import { fetchAccount } from "../../api/Users/Me";
import { searchOnMap } from "../../api/Map";
import { sendUnSubscribe } from "../../api/Thematics/UnSubscribe";
import Item from "../../components/Thematics/Subscribe/Item";
import "./style.css";
import DefaultModal from "../../components/molecules/Modals/Default";
import { PostMe } from "../../api/Users/PostMe";
import { PostAvatar } from "../../api/Users/PostAvatar";
import { ChangePassword } from "../../api/Users/ChangePassword";

import { PasswordCheck } from "../../components/molecules/password-validation/PasswordCheck";
import CropImage from "../../components/molecules/CropImage";
import LocationIcon from "../../components/atoms/icons/location";
import MailIcon from "../../components/atoms/icons/mail";
import PhoneIcon from "../../components/atoms/icons/phone";
import EditIcon from "../../components/atoms/icons/edit";
import ExpertiseEditionModal from "./_components/expertisesEditModal";
import InterventionsEditionModal from "./_components/interventionsEditModal";
import { postBanner } from "api/Users/PostBanner";
import { searchOnEntites } from "api/Entities/searchEntites";
import { fetchExpertises } from "api/Users/Expertises";
import { editEntities } from "api/Entities/editEntities";
import { editGeolocation } from "api/Geolocation/editGeolocation";
import SimilarResult from "./_components/similarResult";

export default function DefaultAccount({
  editPassword = false,
  setEditPassword = () => { },
}) {
  const [accountInfo, setAccountInfo] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [avatarBlob, setAvatarBlob] = useState(null);
  const [banner, setBanner] = useState(null);
  const [bannerBlob, setBannerBlob] = useState(null);
  const [loading, setLoading] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState([]);
  const [changeInformations, setChangeInformations] = useState({
    firstname: null,
    lastname: null,
    branchId: null,
    job: null,
    phone: null,
    password: null,
    passwordconfirm: null,
    entity: null,
    location: {},
    interventions: [],
    expertises: [],
  });

  const [secondaryPassword, setSecondaryPassword] = useState(null);
  const [password, setPassword] = useState(null);
  const [length, setLength] = useState(false);
  const [maj, setMaj] = useState(false);
  const [min, setMin] = useState(false);
  const [number, setNumber] = useState(false);
  const [special, setSpecial] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [entity, setEntity] = useState("");
  const [entities, setEntities] = useState([]);
  const [localisation, setLocalisation] = useState("");
  const [errorUpdateUser, setErrorUpdateUser] = useState("");
  const [adrLocArray, setAdrLocArray] = useState([]);
  const [expertises, setExpertises] = useState(null);
  const [similarEntity, setSimilarEntity] = useState(null);
  const [isOpenExpertisesModal, setIsOpenExpertisesModal] = useState(false);
  const [isOpenInterventionsModal, setIsOpenInterventionsModal] =
    useState(false);

  useEffect(() => {
    if (password) {
      if (/^(.{12,})/.test(password)) setLength(true);
      else setLength(false);

      if (/^(?=.*[a-z])/.test(password)) setMin(true);
      else setMin(false);

      if (/^(?=.*[A-Z])/.test(password)) setMaj(true);
      else setMaj(false);

      if (/^(?=.*\d)/.test(password)) setNumber(true);
      else setNumber(false);

      if (/^(?=.*[!@#$%^&*()+_\-=}{[\]|:;"/?.><,`~])/.test(password))
        setSpecial(true);
      else setSpecial(false);

      if (password === secondaryPassword) setFetch(true);
      else setFetch(false);
    } else {
      setLength(false);
      setMin(false);
      setMaj(false);
      setNumber(false);
      setSpecial(false);
      setFetch(false);
    }
  }, [password, secondaryPassword]);

  useEffect(() => {
    if (accountInfo) {
      const userLocation = accountInfo?.geolocation?.filter(
        (item) => item.type === "LOC"
      )[0];
      console.log("accountInfo -> ", accountInfo);
      setChangeInformations({
        ...changeInformations,
        firstname: accountInfo.firstname,
        lastname: accountInfo.lastname,
        job: accountInfo.job,
        entity: accountInfo.Entity,
        phone: accountInfo.phone,
        expertises: accountInfo?.Expertise || [],
        location: userLocation,
        interventions: accountInfo?.InterventionZone || [],
        branchId: accountInfo?.Branch?.id
      });

      if (accountInfo?.Entity) setEntity(accountInfo?.Entity.name);
      if (userLocation) setLocalisation(userLocation.name.toLowerCase());
      setAvatar(accountInfo.avatar);
    }
  }, [accountInfo]);

  const changeAvatar = (event) => {
    setAvatar(event.target.files[0]);
  };

  const changeBanner = (event) => {
    setBanner(event.target.files[0]);
  };

  async function getAccount() {
    setAccountInfo(await fetchAccount());
    setExpertises(await fetchExpertises());
  }

  const handleChangeExpertises = (expertises) => {
    setChangeInformations({
      ...changeInformations,
      expertises,
    });
  };

  const handleChangeInterventions = (interventions) => {
    setChangeInformations({
      ...changeInformations,
      interventions,
    });
  };

  const getFormatedLocation = () => {
    if (!accountInfo?.geolocation?.length) return null;

    const address = accountInfo?.geolocation?.filter(
      (item) => item.type === "LOC"
    )[0]?.name;

    return address;
  };

  const handleSelectSimilarEntity = async (cancel) => {
    setLoading(true);
    if (cancel) {
      console.log("conserver l'entité");
      await editEntities(accountInfo.id, null, entity, true);
    } else {
      console.log("correction de l'entité");
      await editEntities(accountInfo.id, similarEntity.id, null, true);
    }
    setLoading(false);
    window.location.reload();
  };

  const postInformations = async (e) => {
    e.preventDefault();
    const { firstname, lastname, job, location, phone, branchId } = changeInformations;

    try {
      setLoading(true);
      if (avatar || banner || (firstname && lastname && job)) {
        // edit avatar
        if (avatar && avatar.name) {
          const bodyFormData = new FormData();
          bodyFormData.append("avatar", avatar);
          await PostAvatar(bodyFormData, accountInfo.id);
        }

        // edit banner
        if (banner && banner.name) {
          const bodyFormData = new FormData();
          bodyFormData.append("banner", banner);
          await postBanner(bodyFormData, accountInfo.id);
        }

        // edit location
        if (location) {
          await editGeolocation(
            accountInfo.id,
            null,
            [
              {
                name: location.name,
                type: "LOC",
                place_id: location.place_id,
                latitude: location.latitude,
                longitude: location.longitude,
              },
            ],
            "LOC"
          );
        } else {
          setErrorUpdateUser(
            "Veuillez sélectionner une localisation dans la liste proposée"
          );
          setLoading(false);
          return;
        }

        // edit user informations
        if (firstname && lastname && job) {
          await PostMe({
            firstname: firstname,
            lastname: lastname,
            branchId: branchId,
            job: job,
            phone: phone,
          });
        }

        // edit entity (if user has entity)
        if (changeInformations.entity) {
          await editEntities(
            accountInfo.id,
            changeInformations.entity.id,
            null,
            true
          );
        } else if (entity.length > 0) {
          const response = await editEntities(
            accountInfo.id,
            null,
            entity,
            false
          );
          if (response.message === "Similarity") {
            setSimilarEntity(response.entity);
            setLoading(false);
            return;
          }
        }

        // close modal
        setOpenModal(false);
        window.location.reload();
        // setAccountInfo(await fetchAccount());
      }
      setLoading(false);
    } catch (error) {
      // TODO: Handle error properly
      // console.log(error);
      setLoading(false);
    }
  };

  const postPassword = async (e) => {
    e.preventDefault();
    const { password, passwordconfirm } = changeInformations;
    try {
      setLoading(true);
      if (password && passwordconfirm) {
        if (password === passwordconfirm) {
          await ChangePassword({ password: password });
          setErrorMessage([]);
          setEditPassword(false);
        } else {
          throw "Votre mot de passe ne correspond pas à la confirmation";
        }
      } else {
        throw "Vous devez saisir tout les champs";
      }
      setLoading(false);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.errors);
        setErrorMessage(error.response.data.errors);
      } else {
        setErrorMessage([{ message: error }]);
      }
      setLoading(false);
    }
  };

  const unSubscribe = async (e) => {
    e.preventDefault();
    const idThematic = e.target.id.split("-");
    if (idThematic[1]) {
      try {
        await sendUnSubscribe(idThematic[1]);
        e.target.parentElement.parentElement.remove();
      } catch (error) { }
    }
  };

  const renderAvatarForm = () => {
    if (avatarBlob) {
      return avatarBlob;
    } else if (accountInfo && accountInfo.avatarPath) {
      return accountInfo.avatarPath;
    } else {
      return "/assets/icons/user-solid.svg";
    }
  };

  const renderBannerForm = () => {
    if (bannerBlob) {
      return bannerBlob;
    } else if (accountInfo && accountInfo.bannerPath) {
      return accountInfo.bannerPath;
    } else {
      return "/assets/img/banner-profil.png";
    }
  };

  const locationAddressHandler = async (value) => {
    if (value.length > 2) {
      setAdrLocArray(null);
      const adrTab = await searchOnMap(value);
      setAdrLocArray(adrTab);
    }
  };

  const entitiesHandler = async (value) => {
    if (value.length > 2) {
      setEntities(null);
      const response = await searchOnEntites(value);
      setEntities(response);
    }
  };

  const onSelectAddress = (e, item) => {
    e.preventDefault();
    setChangeInformations({
      ...changeInformations,
      location: {
        name: item.display_name.toLowerCase(),
        type: "LOC",
        place_id: item.place_id,
        latitude: Number(item.lat),
        longitude: Number(item.lon),
      },
    });
    setLocalisation(item.display_name.toLowerCase());
    setAdrLocArray([]);
  };

  const onSelectEntity = (e, item) => {
    e.preventDefault();
    console.log("item", item);
    setChangeInformations({
      ...changeInformations,
      entity: item,
    });
    setEntity(item.name);
    setEntities([]);
  };

  useEffect(() => {
    getAccount();
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(
      () => locationAddressHandler(localisation),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [localisation]);

  useEffect(() => {
    const timeOutId = setTimeout(() => entitiesHandler(entity), 500);
    return () => clearTimeout(timeOutId);
  }, [entity]);

  return (
    <>
      <div className="account__informations">
        {accountInfo && (
          <>
            <div className="account__personal_info">
              <div
                className="account__personal_header"
                style={{
                  backgroundImage: `url(${accountInfo?.bannerPath || "/assets/img/banner-profil.png"
                    })`,
                }}
              >
                <button
                  className="account__edit scale-on-hover"
                  onClick={() => setOpenModal(true)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8.553"
                    height="13.049"
                    viewBox="0 0 8.553 13.049"
                  >
                    <path
                      d="M115.978,101.43l.235-.359a2.8,2.8,0,0,0,0-2.81,1.021,1.021,0,0,0-1.839,0l-.256.391a.353.353,0,0,0-.367.187L109,106.1c-.009.014-.018.03-.027.045a.8.8,0,0,0-.075.2l-.822,3.5a.928.928,0,0,0,.1.692.336.336,0,0,0,.453.155l2.294-1.257a.464.464,0,0,0,.16-.156l4.4-6.728a1.872,1.872,0,0,1,0,1.873l-1.778,2.716a2.6,2.6,0,0,0,.061,2.717.339.339,0,0,0,.613,0,.927.927,0,0,0,0-.937l-.061-.094a.743.743,0,0,1,0-.75l1.778-2.716a3.738,3.738,0,0,0,0-3.747Zm-.684-2.425a.374.374,0,0,1,.307.192.94.94,0,0,1,0,.937l-.194.3-.613-.937.194-.3a.374.374,0,0,1,.306-.192Zm-6.091,9.969.282-1.2.5.77Zm1.576-1.1-.858-1.311,4.138-6.323.858,1.311Z"
                      transform="translate(-108.051 -97.684)"
                      fill="#6e6e6e"
                    />
                  </svg>
                </button>
              </div>
              <div className="account__personal_avatar">
                {accountInfo.avatarPath ? (
                  <img src={accountInfo.avatarPath} alt="avatar" />
                ) : (
                  <img src="/assets/icons/user-solid.svg" alt="avatar" />
                )}
              </div>
              <div className="account__personal-content">
                <div>
                  <span>
                    {accountInfo.Role ? accountInfo.Role[0].name : ""}
                  </span>
                  <h2>
                    {accountInfo.firstname} {accountInfo.lastname}
                  </h2>
                  <h4>{accountInfo.job}</h4>
                  <br />
                  <h4
                    style={{ textTransform: "uppercase", fontWeight: "bold" }}
                  >
                    {accountInfo?.Entity?.name}
                  </h4>
                  <h4>{accountInfo.Branch ? accountInfo.Branch.name : ""}</h4>
                  {/* <p className="account__email">{accountInfo.email}</p>
                  <p>
                    {accountInfo.job} -{" "}
                    {accountInfo.Branch ? accountInfo.Branch.name : ""}
                  </p>
                  <br />
                  {accountInfo.wingzy ? (
                    <p>
                      <a href={accountInfo.wingzy} target="_blank">
                        Annuaire RH Groupe - Profil
                      </a>
                    </p>
                  ) : (
                    ""
                  )} */}
                </div>
                <ul className="account__personal-contact">
                  <li>
                    <LocationIcon />
                    {getFormatedLocation() ? (
                      <p style={{ textTransform: "capitalize" }}>
                        {getFormatedLocation()}
                      </p>
                    ) : (
                      <p>Aucune adresse enregistrée</p>
                    )}
                  </li>
                  <li>
                    <MailIcon />
                    <p>{accountInfo?.email}</p>
                  </li>
                  <li>
                    <PhoneIcon />
                    <p>{accountInfo?.phone || "Pas de numéro enregistré"}</p>
                  </li>
                </ul>

                {/* <button
                  class="btn btn-fill bg-main-hover btn-small text-main"
                  onClick={() => setOpenPasswordModal(true)}
                >
                  CHANGER DE MOT DE PASSE
                </button> */}
                <div className="account__referent">
                  {accountInfo.ReferentOfMainThemes ? (
                    <>
                      <h3>Référent Groupe</h3>
                      <ul>
                        {accountInfo.GroupReferentOfMainThemes.map(
                          (item, index) => (
                            <li key={index}>
                              <img
                                src={`/assets/img/icons/${item.icon
                                  }`}
                              />
                              <p>{item.name}</p>
                            </li>
                          )
                        )}
                      </ul>
                      <ul>
                        {accountInfo.GroupReferentOfSubThemes.map(
                          (item, index) => (
                            <li key={index}>
                              <div
                                className={`account_bullet-point bg-${item.color}`}
                              ></div>
                              <p>{item.name}</p>
                            </li>
                          )
                        )}
                      </ul>
                    </>
                  ) : (
                    ""
                  )}
                  {accountInfo.ReferentOfSubThemes ? (
                    <>
                      <h3>Référent Métier</h3>
                      <ul>
                        {accountInfo.ReferentOfMainThemes.map((item, index) => (
                          <li key={index}>
                            <img
                              src={`/assets/img/icons/${item.icon
                                }`}
                              alt={item.name}
                            />
                            <p>{item.name}</p>
                          </li>
                        ))}
                      </ul>
                      <ul>
                        {accountInfo.ReferentOfSubThemes.map((item, index) => (
                          <li key={index}>
                            <div
                              className={`account_bullet-point bg-${item.MainTheme.color}`}
                            ></div>
                            <p>{item.name}</p>
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="account__right-container">
              <div className="home__subscribe_thematics">
                <h2>Thématiques suivies</h2>
                <br />
                <ul>
                  {accountInfo &&
                    accountInfo.MainThemeSubscribed &&
                    accountInfo.MainThemeSubscribed.map((item, index) => (
                      <li onClick={(e) => unSubscribe(e)} key={index}>
                        <Item themeId={item.id} />
                      </li>
                    ))}
                </ul>
              </div>
              <div className="account__capsules-card" id="expertises-card">
                <h2>
                  Missions & Expertises
                  <button onClick={() => setIsOpenExpertisesModal(true)}>
                    <EditIcon />
                  </button>
                </h2>
                <br />
                <div>
                  {changeInformations?.expertises?.map((item, index) => (
                    <span key={index}>{item?.name}</span>
                  ))}
                </div>
              </div>
              <div className="account__capsules-card" id="interventions-card">
                <h2>
                  Périmètres d’intervention
                  <button onClick={() => setIsOpenInterventionsModal(true)}>
                    <EditIcon />
                  </button>
                </h2>
                <br />
                <div>
                  {changeInformations?.interventions?.map((item, index) => (
                    <span key={index}>{item.name}</span>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <DefaultModal
        isOpen={openModal}
        header={false}
        setIsOpen={setOpenModal}
        className="default-modal__profile"
      >
        <div
          className="header-back-div"
          onClick={() => {
            setOpenModal(!openModal);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12.218"
            height="8.149"
            viewBox="21.012 62.676 12.218 8.149"
          >
            <path
              d="M25.439 62.832a.555.555 0 0 1 .004.78l-2.576 2.585H32.683a.55.55 0 0 1 .548.552.55.55 0 0 1-.548.552h-9.816l2.58 2.584a.55.55 0 1 1-.78.777l-3.498-3.522a.62.62 0 0 1-.114-.174.553.553 0 0 1 .115-.598l3.496-3.523a.54.54 0 0 1 .773-.013Z"
              fill="#6e6e6e"
              fill-rule="evenodd"
              data-name="Icon ionic-ios-arrow-round-back"
            />
          </svg>
          Retour
        </div>
        <form onSubmit={postInformations}>
          <div className="picture-update-container">
            <span className="container-title-input">
              <h2 className="title-input">Photo de profil</h2>
              <div>
                <button className="button-update-picture">modifier</button>
                <input
                  type="file"
                  placeholder="Avart"
                  name="avatar"
                  onChange={changeAvatar}
                />
              </div>
            </span>
            <div className="picture-input">
              <img src={renderAvatarForm()} alt="" />
            </div>
          </div>
          <div width="100%" style={{ borderBottom: "1px solid #E8E8ED" }} />
          <div className="picture-update-container">
            <span className="container-title-input">
              <h2 className="title-input">Photo de couverture</h2>
              <div>
                <button className="button-update-picture">modifier</button>
                <input
                  type="file"
                  placeholder="Banner"
                  name="banner"
                  onChange={changeBanner}
                />
              </div>
            </span>
            <div className="banner-input">
              <img src={renderBannerForm()} alt="" />
            </div>
          </div>
          <div width="100%" style={{ borderBottom: "1px solid #E8E8ED" }} />
          <h2 className="title-input" style={{ margin: "10px 0" }}>
            Informations
          </h2>

          {/* Name input */}
          <div className="container-name-inputs">
            <div className="input_container">
              <label form="firstname">Prénom</label>
              <input
                id="firstname"
                name="firstname"
                type="text"
                placeholder="Exemple : John"
                value={changeInformations.firstname}
                onChange={(event) =>
                  setChangeInformations({
                    ...changeInformations,
                    firstname: event.target.value,
                  })
                }
              />
            </div>
            <div className="input_container">
              <label form="lastname">Nom</label>
              <input
                id="lastname"
                name="lastname"
                type="text"
                placeholder="Exemple : Doe"
                value={changeInformations.lastname}
                onChange={(event) =>
                  setChangeInformations({
                    ...changeInformations,
                    lastname: event.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="input_container">
            <label form="entity">Entité</label>
            <input
              id="entity"
              name="entity"
              type="text"
              placeholder="Exemple : Axione"
              value={entity}
              onChange={(e) => {
                setEntities([]);
                setEntity(e.target.value);
                setChangeInformations({
                  ...changeInformations,
                  entity: null,
                });
              }}
            />

            <ul className="search-list__content">
              {entities === null ? (
                <li>Chargement...</li>
              ) : (
                entities.map((item) => (
                  <li key={item.id} onClick={(e) => onSelectEntity(e, item)}>
                    {item.name}
                  </li>
                ))
              )}
            </ul>
          </div>
          {similarEntity && (
            <SimilarResult
              newText={similarEntity}
              currentText={entity}
              onSelected={(value) => {
                handleSelectSimilarEntity(value === entity);
              }}
            />
          )}

          {/* Job input */}
          <div className="input_container">
            <label form="job">Poste</label>
            <input
              id="job"
              name="job"
              type="text"
              placeholder="Exemple : Responsable"
              value={changeInformations.job}
              onChange={(event) =>
                setChangeInformations({
                  ...changeInformations,
                  job: event.target.value,
                })
              }
            />
          </div>

          <div className="input_container">
            <label form="phone">Téléphone</label>
            <input
              id="phone"
              name="phone"
              placeholder="+33 6 93 00 99 88"
              type="text"
              value={changeInformations.phone}
              onChange={(event) =>
                setChangeInformations({
                  ...changeInformations,
                  phone: event.target.value,
                })
              }
            />
          </div>

          {/* Adr input */}
          <div className="input_container">
            <label form="address">Adresse professionnelle</label>

            <input
              id="address"
              name="address"
              type="text"
              placeholder="Exemple : Rue de la Paix, 75000 Paris"
              value={localisation}
              onChange={(e) => setLocalisation(e.target.value)}
            />

            <ul className="search-list__content">
              {adrLocArray === null ? (
                <li>Chargement...</li>
              ) : (
                adrLocArray.map((item, index) => (
                  <li
                    key={item.place_id + index}
                    onClick={(e) => onSelectAddress(e, item)}
                  >
                    {item.display_name}
                  </li>
                ))
              )}
            </ul>
          </div>

          <br />
          {errorUpdateUser.length ? (
            <p className="error-message">{errorUpdateUser}</p>
          ) : null}
          <br />
          <div style={{ textAlign: "center" }}>
            {loading ? (
              <button className="btn-validate">Chargement...</button>
            ) : (
              <button type="submit" className="btn-validate">
                Valider
              </button>
            )}
          </div>
        </form>
      </DefaultModal>
      {avatar ? (
        <CropImage
          setImage={setAvatar}
          setImageBlob={setAvatarBlob}
          src={avatar}
          ratio={4 / 4}
        />
      ) : (
        ""
      )}
      {banner ? (
        <CropImage
          setImage={setBanner}
          setImageBlob={setBannerBlob}
          src={banner}
          ratio={16 / 9}
        />
      ) : (
        ""
      )}
      <DefaultModal
        isOpen={editPassword}
        setIsOpen={setEditPassword}
        title="Changer de mot de passe"
        className="password-modal"
        header={false}
      >
        <div
          className="header-back-div"
          onClick={() => {
            setEditPassword(!editPassword);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12.218"
            height="8.149"
            viewBox="21.012 62.676 12.218 8.149"
          >
            <path
              d="M25.439 62.832a.555.555 0 0 1 .004.78l-2.576 2.585H32.683a.55.55 0 0 1 .548.552.55.55 0 0 1-.548.552h-9.816l2.58 2.584a.55.55 0 1 1-.78.777l-3.498-3.522a.62.62 0 0 1-.114-.174.553.553 0 0 1 .115-.598l3.496-3.523a.54.54 0 0 1 .773-.013Z"
              fill="#6e6e6e"
              fill-rule="evenodd"
              data-name="Icon ionic-ios-arrow-round-back"
            />
          </svg>
          Retour
        </div>
        <h2 className="title-input" style={{ margin: "10px 0" }}>
          Changer de mot de passe
        </h2>
        <form onSubmit={postPassword}>
          <div className="input_container">
            <label form="password">Nouveau mot de passe*</label>
            <input
              id="password"
              name="password"
              type="password"
              placeholder="Entrer votre nouveau mot de passe"
              onChange={(event) => {
                setPassword(event.target.value);
                setChangeInformations({
                  ...changeInformations,
                  password: event.target.value,
                });
              }}
            />
          </div>
          <div className="input_container">
            <label form="passwordconfirm">Confimer nouveau mot de passe*</label>
            <input
              id="passwordconfirm"
              name="passwordconfirm"
              type="password"
              placeholder="Confirmer votre nouveau mot de passe"
              onChange={(event) => {
                setSecondaryPassword(event.target.value);
                setChangeInformations({
                  ...changeInformations,
                  passwordconfirm: event.target.value,
                });
              }}
            />
          </div>

          <PasswordCheck
            length={length}
            maj={maj}
            min={min}
            number={number}
            special={special}
            fetch={fetch}
            callback={setIsValid}
          />

          {ErrorMessage.map((error) => (
            <p className="error-message">- {error.message}</p>
          ))}

          <br />
          <div style={{ "text-align": "center;" }}>
            {loading ? (
              <button className="btn btn-validate bg-main">
                Chargement...
              </button>
            ) : isValid ? (
              <button type="submit" className="btn btn-validate bg-main">
                Valider
              </button>
            ) : (
              <button
                disabled
                className="btn btn-validate bg-main"
                style={{ cursor: "not-allowed" }}
              >
                Valider
              </button>
            )}
          </div>
        </form>
      </DefaultModal>
      <ExpertiseEditionModal
        isOpen={isOpenExpertisesModal}
        setIsOpen={setIsOpenExpertisesModal}
        selectedList={changeInformations?.expertises}
        setSelectedList={handleChangeExpertises}
        userId={accountInfo?.id}
      />
      <InterventionsEditionModal
        isOpen={isOpenInterventionsModal}
        setIsOpen={setIsOpenInterventionsModal}
        selectedList={changeInformations?.interventions}
        setSelectedList={handleChangeInterventions}
        userId={accountInfo?.id}
      />
    </>
  );
}
