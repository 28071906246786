import React, { useState, useEffect } from "react";

import SharesPages from "../../Shares/SharesPage";
import SharesForm from "./SharesForm";

import { fetchOneFile } from "../../../api/Files";

import "../../Shares/Shares.css";

import CenterLayout from "../../../layouts/CenterLayout";
import { LittleCard } from "../../../components/molecules/AdminPanel/index";
import { useParams } from "react-router";

export default function Shares({ propsEditing }) {
  const [edit, setEdit] = useState(false);
  const [share, setShare] = useState(false);
  const { id } = useParams();

  const getOneShare = async () => {
    setShare(await fetchOneFile(id));
  };

  useEffect(() => {
    if (propsEditing && id) {
      getOneShare();
      setEdit(true);
    }
  }, [propsEditing]);

  return (
    <CenterLayout title="Shares" className="Shares_Pages">
      {edit ? (
        <SharesForm
          id="admin-shares-form"
          onClick={() => setEdit(false)}
          shareProps={propsEditing ? share : null}
        />
      ) : (
        <div className="admin-display-littlecard-and-other-things">
          <div style={{ marginTop: "-5%", zIndex: "-1" }} />
          <LittleCard
            buttonText="Ajouter un document"
            icon="icon-write-bubble"
            onClick={() => setEdit(true)}
            style={{ marginTop: "-30px" }}
          />
          <div>
            <SharesPages />
          </div>
        </div>
      )}
    </CenterLayout>
  );
}
