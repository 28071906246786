import axios from "axios";
import getFormatedToken from "../../helpers/getFormatedToken";
import Cookies from "universal-cookie";
export async function postBanner(banner, id) {
  const cookies = new Cookies();
  const response = await axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/api/users/${id}/banner`,
    data: banner,
    headers: {
      Authorization: `Bearer ${cookies.get("::token")}`,
      "Content-Type": "multipart/form-data",
    },
    validateStatus: () => true,
  });
}
