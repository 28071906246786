import axios from "axios";
import getFormatedToken from "../../helpers/getFormatedToken";

export async function fetchExpertises() {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/users/me`,
    getFormatedToken()
  );

  const { data } = response.data;
  console.log("fetchExpertises -> ", data);
  return data;
}
