import OptionsIcon from "components/atoms/icons/options";
import { PencilIcon } from "components/atoms/icons/pencil";
import { TrashIcon } from "components/atoms/icons/trash";
import DefaultModal from "components/molecules/Modals/Default";
import users from "pages/Annuaire/_components/content/users.utils";
import { useState } from "react";
import { CommentFooter } from "./CommentFooter";
import CommentForm from "./CommentForm";
import { UnderCommentContent } from "./UnderCommentContent";

export const UnderComment = ({
  underComment,
  avatarPath,
  messageLiked,
  socket,
  sendUnderComment,
  userId,
  onDeleteComment,
  onUpdateComment,
  users = [],
}) => {
  const [isUnderCommenting, setIsUnderCommenting] = useState(false);

  const handleComment = (value) => {
    setIsUnderCommenting(value);
    setTimeout(async () => {
      const inputMessage = document.getElementById(
        `comment-form-${value?.messageId}`
      );

      inputMessage.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
      inputMessage.focus({ preventScroll: true });
    }, 100);
  };

  return (
    <div className="under-comment-box">
      {underComment?.map((comment) => (
        <UnderCommentContent
          comment={comment}
          onComment={handleComment}
          avatarPath={avatarPath}
          messageLiked={messageLiked}
          socket={socket}
          userId={userId}
          onUpdateComment={onUpdateComment}
          onDeleteComment={onDeleteComment}
          users={users}
        />
      ))}

      {isUnderCommenting && (
        <CommentForm
          sender={{
            sendFct: sendUnderComment,
            messageId: isUnderCommenting.messageId,
          }}
          comment={isUnderCommenting}
          avatarPath={avatarPath}
          isComment={true}
          users={users}
        />
      )}
    </div>
  );
};
