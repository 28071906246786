import React, { useState, useEffect } from "react";
import FilterForm from "../../../components/Filter/FilterForm";
import SharesContent from "../../../components/ContentListShares";
import { Card } from "../../../components/molecules/AdminPanel/index";

import { postShares, updateShares } from "../../../api/Shares";
import ErrorDiv from "../../../components/molecules/ErrorsMessages/errorForm";
import { FiltersContext } from "../../../contexts/filters";

import "./SharesForm.css";
import FirstDropdown from "../../../components/TopBar/MobileNavbar/FirstDropdown";
import filetypes from "../../../utils/filetypes.json";
import { Link, useHistory, useParams } from "react-router-dom";
import LoadingBar from "../../../components/molecules/Loaders/Bar";

export default function SharesForm({ onClick, shareProps }) {
  const [title, setTitle] = useState();
  const [link, setLink] = useState();
  const [description, setDescription] = useState();
  const [hashtag, setHashtag] = useState(null);
  const [file, setFile] = useState();
  const [isOfficial, setIsOfficial] = useState(null);
  const [fileType, setfFileType] = useState();
  const [filter, setFilter] = useState([]);
  const [errorF, setErrorF] = useState(false);
  const [sending, setSending] = useState(false);

  const { id } = useParams();
  const history = useHistory();

  const conversionDuTableauAvantLenvoie = () => {
    let mainThemes = [];
    let subThemes = [];
    let branches = [];

    filter.forEach((row, index) => {
      if (row.includes("main_theme")) {
        mainThemes.push(row.split(":")[1]);
      } else if (row.includes("sub_theme")) {
        subThemes.push(row.split(":")[1]);
      } else if (row.includes("branch")) {
        branches.push(row.split(":")[1]);
      }
    });
    return { mainThemes, subThemes, branches };
  };

  const createShare = async (e) => {
    if (isOfficial === null) {
      setErrorF("Veuillez sélectionner la catégorie du document");
      return;
    }
    if (!title) {
      setErrorF("Veuillez ajouter un titre");
      return;
    }
    if (!link && !file) {
      setErrorF("Veuillez ajouter un lien ou un fichier");
      return;
    }
    e.preventDefault();
    let filt = conversionDuTableauAvantLenvoie();
    if (!filt) {
      filt = {};
    }
    if (id) {
      const res = await updateShares(
        id,
        title,
        link,
        description,
        hashtag,
        file,
        isOfficial,
        fileType,
        filt.branches,
        filt.mainThemes,
        filt.subThemes
      );
      if (!res) {
        history.push("/admin/shares");
        return;
      }
      setErrorF("Une erreur est survenue...");
    } else {
      if (!hashtag) {
        setErrorF("Ajoutez au moins 1 hashtag...");
        return;
      }
      const res = await postShares(
        title,
        link,
        description,
        hashtag,
        file,
        isOfficial,
        fileType ? fileType : 1,
        filt.branches,
        filt.mainThemes,
        filt.subThemes
      );
      if (!res) {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
      setErrorF("Une erreur est survenue...");
    }
    onClick();
  };

  useEffect(() => {
    const zob = document.querySelector(".reset_filter_title");
    const zob2 = document.querySelector(".calendar_selection");
    zob.style = "display: none";
    zob2.style = "display: none";

    if (shareProps) {
      setIsOfficial(shareProps.isOfficial);
      setTitle(shareProps.title);
      setLink(shareProps.link);
      let hashtagString = "";
      shareProps.hashtags.forEach((elem, index) => {
        hashtagString = `${hashtagString}${index ? "," : ""}${elem
          .replace(/#/g, "")
          .replace(/ /g, "")}`;
      });

      setHashtag(hashtagString);
      setDescription(shareProps.description);
      setfFileType(shareProps.fileType);
    }
  }, [shareProps]);

  return (
    <Card>
      <Link to="/admin/shares">
        <a className="back" onClick={onClick}>
          <i className="icon-short-arrow" style={{ fontSize: "9px" }} /> Retour
        </a>
      </Link>
      <h1 className="title">Description du document</h1>
      <form className="admin-form">
        <div className="admin-input-box">
          <select
            className="admin-input"
            style={{ backgroundColor: "white" }}
            type="text"
            placeholder="Choisir le métier*"
            onChange={(e) => setfFileType(e.target.value)}
            required
          >
            <option disabled default selected value="">
              Choisir le type de fichier*
            </option>
            {filetypes.filetypes.map((filetype) => (
              <option value={filetype.id} selected={filetype.id === fileType}>
                {filetype.name}
              </option>
            ))}
          </select>
          <input
            className="admin-input"
            type="text"
            placeholder="Titre du document*"
            value={title && title}
            required
            onChange={(e) => setTitle(e.target.value)}
          />
          <input
            className="admin-input"
            type="text"
            placeholder="#Hashtag"
            value={hashtag && hashtag}
            onChange={(e) => {
              const temp = e.target.value.replace("#", "").replace(" ", ",");
              setHashtag(temp);
            }}
          />
          <input
            className="admin-input"
            type="text"
            placeholder="Lien du site"
            value={link && link}
            onChange={(e) => setLink(e.target.value)}
          />
          <textarea
            className="admin-input"
            placeholder="Description..."
            value={description && description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <div className="filter_category_button_group">
            <input
              type="radio"
              id="Library"
              name="isOfficial"
              onClick={() => setIsOfficial(true)}
              checked={isOfficial === true}
            />

            <label className={"filter_category_button active"} for="Library">
              <img
                alt={"Library"}
                src={"/assets/icons/CategorieFilter/iconLibrary.svg"}
              />
              <p>Library</p>
            </label>

            <input
              type="radio"
              id="HRTrends"
              name="isOfficial"
              checked={isOfficial === false}
              onClick={() => setIsOfficial(false)}
            />

            <label className={"filter_category_button active"} for="HRTrends">
              <img
                alt={"HR Trends"}
                src={"/assets/icons/CategorieFilter/iconHrTrends.svg"}
              />
              <p>HR Trends</p>
            </label>
          </div>
        </div>
        <label className="upload-style" htmlFor="upload-file">
          <i className="icon-cloud" style={{ fontSize: "2rem" }} />
          <p className="btn btn-small bg-main">
            {/* {shareProps && shareProps.blobPath
              ? shareProps.blobPath.split("/")[
              shareProps.blobPath.split("/").length - 1
              ]
              : file
                ? file.name
                : "Sélectionnez un fichier"} */}
            {file ? file?.name : shareProps ? shareProps.blobPath.split("/")[
              shareProps.blobPath.split("/").length - 1
            ] : "Sélectionnez un fichier"}
          </p>
        </label>
        <input
          id="upload-file"
          type="file"
          onChange={(e) => {
            const file = e.target.files[0];
            if (!file) return;
            const timestamp = new Date().getTime();
            const formatedName = file.name
              .split(".")[0]
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .replace(/ /g, "_");

            const renamedFile = new File(
              [file],
              `${formatedName}-${timestamp}.${file.name.split(".")[1]}`,
              {
                type: file.type,
              }
            );
            console.log("renamedFile -> ", renamedFile);
            setFile(renamedFile);
          }}
          name="file"
        />
      </form>
      <div
        style={{
          borderBottom: "1px rgba(0, 0, 0, 0.15) solid",
          marginBottom: "30px",
        }}
      ></div>
      <div className="admin-fitler-container">
        <FiltersContext.Provider value={shareProps}>
          <FilterForm
            filtersCallback={setFilter}
            category={false}
            edition={true}
          />
        </FiltersContext.Provider>
        {sending ? (
          <button
            className="save-btn"
            style={{ backgroundColor: "#66666b" }}
            disabled
          >
            Enregistrer
          </button>
        ) : (
          <button
            className="save-btn"
            onClick={(e) => {
              createShare(e);
              setSending(true);
              setTimeout(() => {
                setSending(false);
                setErrorF(false);
              }, 8000);
            }}
          >
            Enregistrer
          </button>
        )}
        {errorF && <ErrorDiv message={errorF} />}
        {sending && !errorF && <LoadingBar done={false} />}
      </div>
    </Card>
  );
}
