import axios from "axios";
import { useLayoutEffect } from "react";
import getFormatedToken from "../helpers/getFormatedToken";

export async function fetchDashboard() {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/dashboard`,
      getFormatedToken()
    );

    const { data } = response.data;
    return data.dashboard || [];
  } catch (error) {
    // TODO: Handle error properly
    // console.log(error);
  }
}

export async function updateDashboard({ n1, n2, n3, n4, banner }) {
  try {
    const formData = new FormData();
    formData.append("n1", JSON.stringify(n1));
    formData.append("n2", JSON.stringify(n2));
    formData.append("n3", JSON.stringify(n3));
    formData.append("n4", JSON.stringify(n4));
    if (banner) {
      formData.append("banner", banner);
    }

    await axios({
      method: "PUT",
      url: `${process.env.REACT_APP_API_URL}/api/dashboard`,
      data: formData,
      ...getFormatedToken(true),
      validateStatus: () => true,
    });
  } catch (error) {
    return error;
  }
}
