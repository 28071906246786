import React, { useEffect, useState } from 'react'

// Context
import { FilterContext } from './context'

// Components
import Dropdown from './Dropdown'
import Item from './Item'
import ResetButton from './ResetButton'

function Filter({ className, initialFilters = [], onChange, children }) {
  const [filters, setFilters] = useState([])

  useEffect(
    () => {
      onChange(filters)
    },
    [filters]
  )


  useEffect(
    () => {
      if(filters.length === 0 && initialFilters.length > 0){
        setFilters(initialFilters)
      }
      
    },
    [initialFilters]
  )

  const resetFilters = () => document.location.reload()

  const isFilterActive = (filter) => filters.includes(filter)

  const toggleFilter = (filter, force = null) => {
    setFilters((filters) => {
      if (force !== null) {
        return (force) ? [...filters, filter] : filters.filter((v) => (filter === v) ? force:true)
      }

      return (isFilterActive(filter))
        ? filters.filter((v) => v !== filter)
        : [...filters, filter]
    })
  }


  return (
    <div className={className}>
      <FilterContext.Provider value={{ filters, resetFilters, toggleFilter, isFilterActive }}>
        { children }
      </FilterContext.Provider>
    </div>
  )
}

Filter.Dropdown = Dropdown
Filter.Item = Item
Filter.ResetButton = ResetButton

export default Filter
