import React, { useEffect, useState } from "react";
import CenterLayout from "../../layouts/CenterLayout";
import SharesPages from "./SharesPage";

export default function Shares() {
  return (
    <CenterLayout title="Library" className="Shares_Pages">
      <SharesPages />
    </CenterLayout>
  );
}
