import React, { useState, useEffect } from "react";
import CenterLayout from "../../layouts/CenterLayout";
import EventPage from "./EventPage";

export default function Events() {
  return (
    <CenterLayout title="Events" className={false}>
      <EventPage />
    </CenterLayout>
  );
}
