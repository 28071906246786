import { useEffect, useState } from "react";

// styles
import "./phototheque.css";

// components
import DefaultLayout from "layouts/DefaultLayout";
import TabBar from "./_components/TabBar";
import FormPhototheque from "./_components/Form";
import { deletePhoto, getPhotos } from "api/Phototheque";
import Thumbnail from "./_components/ItemThumbnail";
import ItemThumbnail from "./_components/ItemThumbnail";
import ItemList from "./_components/ItemList";

export default function PhotothequePage() {
  const [viewMode, setViewMode] = useState("thumbnail");
  const [selectedPhotos, setSelectedPhotos] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [formIsOpen, setFormIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("alphabetical_asc");

  useEffect(() => {
    const fetchPhotos = async () => {
      const photos = await getPhotos();
      console.log(photos);
      setPhotos(photos);
    };
    fetchPhotos();
  }, []);

  const handleSubmitForm = (data) => {
    setFormIsOpen(false);
    const { type, response } = data;
    setSelectedPhotos(null);

    if (type === "create") {
      setPhotos([...photos, response]);
    }
    if (type === "update") {
      const index = photos.findIndex((photo) => photo.id === response.id);
      setPhotos([
        ...photos.slice(0, index),
        response,
        ...photos.slice(index + 1),
      ]);
    }
  };

  const handleDelete = async (photo) => {
    if (
      window.confirm(
        `Êtes vous sûr de vouloir suprimer la photo de ${photo.firstname} ${photo.lastname} ?`
      )
    ) {
      await deletePhoto(photo.id);
      const index = photos.findIndex((p) => p.id === photo.id);
      setPhotos([...photos.slice(0, index), ...photos.slice(index + 1)]);
    }
  };

  return (
    <DefaultLayout>
      {formIsOpen ? (
        <div className="phototheque-page__form">
          <FormPhototheque
            onCancel={() => {
              setFormIsOpen(false);
              setSelectedPhotos(null);
            }}
            onSubmit={handleSubmitForm}
            photo={selectedPhotos}
          />
        </div>
      ) : (
        <div className="phototheque-page">
          <TabBar
            view={viewMode}
            onChangeView={(view) => setViewMode(view)}
            onSearch={(e) => setSearch(e.target.value)}
            onAddPhoto={() => setFormIsOpen(true)}
          />
          <div className="phototheque-page__sort">
            <p>Trier par :</p>
            <select onChange={(e) => setSortBy(e.target.value)}>
              <option value="alphabetical_asc">Alphabétique (A-Z)</option>
              <option value="alphabetical_desc">Alphabétique (Z-A)</option>
              <option value="customId_asc">ID de photo (1-9)</option>
              <option value="customId_desc">ID de photo (9-1)</option>
            </select>
          </div>
          <div className="phototheque-page__photos">
            {viewMode === "thumbnail" && (
              <ul className="phototheque-page__photos__thumbnails">
                {photos
                  .filter((photo) => {
                    const { firstname, lastname, customId } = photo;
                    const searchValue = search.toLowerCase();
                    return (
                      firstname.toLowerCase().includes(searchValue) ||
                      lastname.toLowerCase().includes(searchValue) ||
                      customId.toLowerCase().includes(searchValue)
                    );
                  })
                  .sort((a, b) => {
                    if (sortBy === "alphabetical_asc") {
                      return a.firstname.localeCompare(b.firstname);
                    }
                    if (sortBy === "alphabetical_desc") {
                      return b.firstname.localeCompare(a.firstname);
                    }
                    if (sortBy === "customId_asc") {
                      return a.customId.localeCompare(b.customId);
                    }
                    if (sortBy === "customId_desc") {
                      return b.customId.localeCompare(a.customId);
                    }
                  })
                  .map((photo) => (
                    <ItemThumbnail
                      item={photo}
                      onEdit={(photo) => {
                        setSelectedPhotos(photo);
                        setFormIsOpen(true);
                      }}
                      onDelete={handleDelete}
                    />
                  ))}
              </ul>
            )}
            {viewMode === "list" && (
              <table className="phototheque-page__photos__list">
                <tbody>
                  {photos
                    .filter((photo) => {
                      const { firstname, lastname, customId } = photo;
                      const searchValue = search.toLowerCase();
                      return (
                        firstname.toLowerCase().includes(searchValue) ||
                        lastname.toLowerCase().includes(searchValue) ||
                        customId.toLowerCase().includes(searchValue)
                      );
                    })
                    .sort((a, b) => {
                      if (sortBy === "alphabetical_asc") {
                        return a.lastname.localeCompare(b.lastname);
                      }
                      if (sortBy === "alphabetical_desc") {
                        return b.lastname.localeCompare(a.lastname);
                      }
                      if (sortBy === "customId_asc") {
                        return a.customId.localeCompare(b.customId);
                      }
                      if (sortBy === "customId_desc") {
                        return b.customId.localeCompare(a.customId);
                      }
                    })
                    .map((photo) => (
                      <ItemList
                        item={photo}
                        onEdit={(photo) => {
                          setSelectedPhotos(photo);
                          setFormIsOpen(true);
                        }}
                        onDelete={handleDelete}
                      />
                    ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      )}
    </DefaultLayout>
  );
}
