import EditIcon from "components/atoms/icons/edit";
import LinkIcon from "components/atoms/icons/link";
import { TrashIcon } from "components/atoms/icons/trash";
import { useState } from "react";

export default function ButtonsAction({ item, onEdit, onDelete }) {
  const [isCopied, setIsCopied] = useState(false);
  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_API_URL}${item.avatar}`
    );
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };
  return (
    <div className="phototheque-page__photos__item__buttons">
      <button id="edit" onClick={() => onEdit(item)}>
        <span>Modifier</span>
        <EditIcon />
      </button>
      <button id="link" onClick={handleCopyLink}>
        <span>{isCopied ? "Copié !" : "Copier le lien"}</span>
        <LinkIcon />
      </button>
      <button id="delete" onClick={() => onDelete(item)}>
        <span>Supprimer</span>
        <TrashIcon />
      </button>
    </div>
  );
}
