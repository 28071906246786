import React, { useState, useEffect } from "react";
import "./style.css";
import User from "../../molecules/Cards/User";
import EventCard from "../../molecules/Cards/Event";
import moment from "moment";

import { fetchIsSubscribed } from "../../../api/Thematics/IsSubscribed";
import { sendUnSubscribe } from "../../../api/Thematics/UnSubscribe";
import { sendSubscribe } from "../../../api/Thematics/Subscribe";

import { Link } from "react-router-dom";
import ReferentModal from "../../molecules/Modals/Referent";

export default function Header({
  data: {
    name,
    description,
    color,
    id,
    bannerImgPath,
    GroupReferent,
    Referents,
    Event,
    icon,
  },
}) {
  const [isSubscribed, setIsSubscribed] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [nextEvent, setNextEvent] = useState([]);

  async function getIsSubscribed(id) {
    setIsSubscribed(null);
    setIsSubscribed(await fetchIsSubscribed(id));
  }

  async function getIsSubscribed(id) {
    setIsSubscribed(null);
    setIsSubscribed(await fetchIsSubscribed(id));
  }

  function filtreClosestDate(sessions) {
    let EventSessionInDate = [];
    for (const key in sessions) {
      EventSessionInDate.push({
        days: moment(sessions[key].startDate).diff(),
        ...sessions[key],
      });
    }

    const closest = EventSessionInDate.reduce((a, b) => {
      if (b.days >= 0 && a.days >= 0) {
        return b.days < a.days ? b : a;
      } else if (a.days >= 0) {
        return a;
      } else if (b.days >= 0) {
        return b;
      }
    });

    return closest.startDate;
  }

  function getNextEvent(events) {
    let eventFilter = [];
    events
      .filter((article) => article.Content.state === "PUBLISHED")
      .sort(function (a, b) {
        return (
          new Date(filtreClosestDate(a.EventSession)) -
          new Date(filtreClosestDate(b.EventSession))
        );
      })
      .map((event) => {
        eventFilter.push(event);
      });
    setNextEvent(eventFilter);
  }

  useEffect(() => {
    getIsSubscribed(id);
  }, [name]);

  useEffect(() => {
    if (Event && Event.length > 0) {
      getNextEvent(Event);
    } else {
      setNextEvent([]);
    }
  }, [Event]);

  const subscribe = async () => {
    setIsSubscribed(null);
    if (isSubscribed) {
      await sendUnSubscribe(id);
      setIsSubscribed(false);
    } else {
      await sendSubscribe(id);
      setIsSubscribed(true);
    }
  };
  return (
    <React.Fragment>
      <header
        className="themactics__header"
        style={{
          backgroundImage: `url('${bannerImgPath ? bannerImgPath : ""}')`,
        }}
      >
        <div
          className="themactics__header-imgae"
          style={{
            backgroundImage: `url('${bannerImgPath ? bannerImgPath : ""}')`,
          }}
        ></div>
        <div className="themactics__header-title">
          <h1 className={`text-${color}`}>{name}</h1>
          <p>{description}</p>
          {isSubscribed === null ? (
            <button className={`btn bg-${color}`}>Chargement...</button>
          ) : isSubscribed === true ? (
            <button
              className={`btn btn-fill text-${color} bg-${color}-hover border-${color}`}
              onClick={subscribe}
            >
              ne plus suivre cette thématique
            </button>
          ) : (
            <button className={`btn bg-${color}`} onClick={subscribe}>
              suivre cette thématique
            </button>
          )}
        </div>
        <div className="themactics__header-widget">
          <div>
            {GroupReferent && GroupReferent.id ? (
              <>
                <h2>
                  <i className="icon-idea"></i>Group referents
                </h2>

                <User data={GroupReferent} />
              </>
            ) : (
              ""
            )}

            {Referents && Referents.length ? (
              <User
                dataToggle={{
                  icon: "idea",
                  title: "Référents Métiers",
                  description: "sur la thématique",
                  color: color,
                }}
                toggle={setOpenModal}
              />
            ) : (
              " "
            )}
          </div>

          <br />
          <br />
          {nextEvent && nextEvent.length ? (
            <div>
              <h2>
                <i className="icon-calendar"></i> Next events
              </h2>
              {nextEvent.map((event, index) => {
                return index < 1 && <EventCard data={event} />;
              })}
              <p style={{ textAlign: "right" }}>
                <Link to="/events">
                  <i className="icon-options_horizontal"></i>
                </Link>
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      </header>
      <ReferentModal
        isOpen={openModal}
        setIsOpen={setOpenModal}
        thematic={{
          icon: icon,
          name: name,
        }}
      >
        {Referents &&
          Referents.sort((a, b) => (a.branchId > b.branchId ? 1 : -1)).map(
            (referent, index) => {
              return <User key={index} data={referent} />;
            }
          )}
      </ReferentModal>
    </React.Fragment>
  );
}
