import axios from "axios";
import getFormatedToken from "../helpers/getFormatedToken";

export async function fetchAllSubthemes() {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/sub-themes`,
    getFormatedToken()
  );
  const { data } = response.data;

  return data.subThemes;
}

export async function updateSubTheme({
  id,
  n1,
  n2,
  n3,
  banner,
  thumbnail,
  content,
  description,
}) {
  try {
    const formData = new FormData();
    formData.append("n1", JSON.stringify(n1));
    formData.append("n2", JSON.stringify(n2));
    formData.append("n3", JSON.stringify(n3));
    if (banner) {
      formData.append("banner", banner);
    }

    if (content) {
      formData.append("Content", JSON.stringify({ lang: "FR", html: content }));
    }
    formData.append("description", description);
    const res1 = await axios({
      method: "PUT",
      url: `${process.env.REACT_APP_API_URL}/api/sub-themes/${id}`,
      data: formData,
      ...getFormatedToken(true, false),
      validateStatus: () => true,
    });

    if (thumbnail) {
    const formData2 = new FormData();

      formData2.append("thumbnail", thumbnail);

      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/api/sub-themes/${id}/thumbnail`,
        data: formData2,
        ...getFormatedToken(true, false),
      });
    }
  } catch (error) {
    // TODO: Handle error properly
    // console.log(error);
    return error;
  }
}
