import React, { useEffect, useState } from "react";
import { MentionsInput, Mention } from "react-mentions";
import { GetAllUsers } from "api/Users/GetAllUsers";

export default function MessageForm({ send, avatarPath, users = [] }) {
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [isDiscussionStarted, setIsDiscussionStarted] = useState(false);
  const [sending, setSending] = useState(false);

  return (
    <div className="message-container">
      <div id="new-message" className="feed-input-box">
        <div
          id="user-pic-msg"
          className="message-img"
          style={{
            backgroundImage: `url(${
              avatarPath ? avatarPath : "/assets/icons/user-solid.svg"
            })`,
          }}
        />

        {isDiscussionStarted ? (
          <MentionsInput
            className="message-input"
            placeholder="Commencer une discussion..."
            name="message"
            onChange={(event) => setMessage(event.target.value)}
            allowSuggestionsAboveCursor={true}
            value={message}
            autoFocus
          >
            <Mention
              trigger="@"
              data={users}
              displayTransform={(id, display) => `@${display}`}
              renderSuggestion={(suggestion) => {
                return (
                  <div className="message-input__suggestions__user">
                    <div
                      className="message-input__suggestions__user__avatar"
                      style={{
                        backgroundImage: `url(${
                          suggestion.avatarPath ||
                          "/assets/icons/user-solid.svg"
                        })`,
                      }}
                    />
                    <p>{suggestion.firstname + " " + suggestion.lastname}</p>
                  </div>
                );
              }}
            />
          </MentionsInput>
        ) : (
          <label
            className="message-input"
            onClick={() => setIsDiscussionStarted(!isDiscussionStarted)}
          >
            Commencer une discussion
          </label>
        )}
      </div>
      <div id="button-box" className="feed-input-box">
        <button
          htmlFor="upload-file "
          className="btn-fill bg-main-hover"
          style={{ position: "relative", cursor: "pointer" }}
        >
          <input
            id="upload-file"
            name="upload-file"
            type="file"
            style={{
              top: 0,
              left: 0,
              cursor: "pointer",
              height: "100%",
              width: "100%",
              position: "absolute",
              opacity: 0,
              zIndex: 0,
            }}
            onChange={(event) => {
              setFile(event.target.files[0]);
              setFileName(event.target.files[0].name);
            }}
          />
          <i className="icon-attachment-clip" />
          <span>{fileName ? fileName : "Pièce jointe"}</span>
        </button>
        {message ? (
          <button
            className="button-strong"
            onClick={() => {
              send({ content: message, file });
              setMessage("");
              setFile(null);
              setSending(true);
            }}
          >
            POSTER <i className="icon-send" />
          </button>
        ) : (
          <button
            disabled
            style={{
              fontFamily: "var(--cera-bold)",
              border: "white solid 1px",
              gap: "10px",
              display: "flex",
              alignItems: "center",
            }}
            className="btn bg-main"
          >
            POSTER <i className="icon-send" />
          </button>
        )}
      </div>
      {/* {sending && <LoadingBar />} */}
    </div>
  );
}
