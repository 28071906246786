import React from "react";
import "./welcome.css";
import { Link } from "react-router-dom";

const Opportunities = ({ opportunities, isHome }) => {
  return (
    <>
      <div>
        <p className="first_title" style={{ fontSize: !isHome && "27px" }}>
          {isHome && <i className="icon-mobyclic"></i>}
          HR Job opportunities
        </p>
        {/* href={opportunitie.url._text} */}
        {opportunities && opportunities.length
          ? opportunities.map((opportunitie, index) => (
              <a
                key={index}
                href={opportunitie.link}
                target="_blank"
                className="welcome__target"
              >
                <div className="welcome__item mb-20 disable-shadow-on-hover">
                  <div className="welcome__second_container">
                    {/* <img
              className="welcome__image"
              src={opportunitie.image}
              alt={opportunitie.titre._text}
            /> */}
                    <div className="welcome__third_container">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: opportunitie.title,
                        }}
                      ></p>
                      <p
                        className="welcome__p1"
                        dangerouslySetInnerHTML={{
                          __html: `${opportunitie.entity} - ${opportunitie.localisation}`,
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              </a>
            ))
          : ""}
        <a
          href="https://mobyclic.bouygues.com/postuler/toutes-les-offres-demploi/?filia=&fil=883&loc=&search="
          target="_blank"
          className="welcome__target"
        >
          <div className="welcome__item tac disable-shadow-on-hover">
            <img src="./assets/img/icons/mobyclic.png" alt="mobyclic" />
            <div className="opportunitie__ligne"></div>
            <p className="opportunitie__txt">
              Retrouvez toutes nos offres sur Mobyclic
            </p>
          </div>
        </a>
      </div>
      {isHome ? (
        <div className="alignright">
          <a
            href="https://mobyclic.bouygues.com/postuler/toutes-les-offres-demploi/?filia=&fil=883&loc=&search="
            target="_blank"
            className="troispetitspoints"
          >
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </a>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default Opportunities;
