import React, { useEffect, useState } from "react";

// :: Components imports
import CenterLayout from "../../layouts/CenterLayout";
import Message from "../../components/Messages/Message";
import MessageForm from "../../components/Messages/MessageForm";

//:: Context import
import SocketContext from "../../contexts/socket";
//:: API functions imports
import { fetchMessages } from "../../api/Messages";
import getFormatedToken from "../../helpers/getFormatedToken";

//:: IO imports
import socketIO from "socket.io-client";
import axios from "axios";

import { fetchAccount } from "../../api/Users/Me";
import Cookies from "universal-cookie";
import { GetAllUsers } from "api/Users/GetAllUsers";
import { useLocation } from "react-router-dom";

const cookies = new Cookies();

const socket = socketIO(process.env.REACT_APP_API_URL, {
  path: "/socket.io",
  auth: { token: cookies.get("::token") },
  transports: ["polling"],
});

export default function MessagesFeed() {
  //const [socket, setSocket] = useState(null);
  const location = useLocation();
  const [messages, setMessages] = useState([]);
  const [avatarPath, setAvatarPath] = useState(null);
  const [userId, setUserId] = useState(null);
  const [messageLiked, setMessageLiked] = useState([]);
  const [isConnected, setIsConnected] = useState(socket?.connected);
  const [users, setUsers] = useState([]);

  const getAccount = async () => {
    const account = await fetchAccount();
    setUserId(account.id);
    setAvatarPath(account.avatarPath);
    setMessageLiked(account.MessageLike || []);
  };

  useEffect(() => {
    getAccount();
  }, []);

  const socketValue = {
    socket,
  };

  const getMessages = async () => {
    const messagesArray = await fetchMessages();
    setMessages(messagesArray);
  };

  const fetchUsers = async () => {
    const usersF = await GetAllUsers();
    // change the format of the data to be used by react-mentions
    usersF.forEach((user) => {
      user._id = user.id;
      user.display = user.firstname + " " + user.lastname;
    });
    setUsers(usersF);
  };

  const getParamsUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("message");
    if (id) {
      setTimeout(() => {
        const message = document.getElementById("messageId-" + id);
        if (message) {
          message.style.outline = "1px solid var(--main-color)";
          message.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });

          // setTimeout(() => {
          //   message.style.outline = "none";
          // }, 10000);
        }
      }, 500);
    }
  };

  useEffect(() => {
    getMessages();
    fetchUsers();
  }, []);

  useEffect(() => {
    getParamsUrl();
  }, [messages, location.search]);

  useEffect(() => {
    socket.on("connect", () => {
      setIsConnected(true);
    });

    socket.on("disconnect", () => {
      setIsConnected(false);
    });

    // Proc when another user post a message
    socket.on("feed:message:new", (_) => {
      getMessages();
    });

    // Proc when another user like a message
    // socket.on("feed:message:like:update", (data) => {
    //   console.log(data);
    // });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("feed:message:new");
    };
  }, []);

  const sendImageAttachment = (file, id) => {
    const formData = new FormData();
    formData.append("file", file);
    new Promise((resolve, reject) => {
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/api/messages/${id}/upload`,
        data: formData,
        ...getFormatedToken(true),
      }).then(function () {
        resolve(true);
      }).catch(function (error) {
        reject();
        resolve(error);
      })
    })
  }

  const sendMessage = (message) => {
    socket.emit(
      "feed:create:message",
      { content: message.content },
      async (err, data) => {
        // error handling
        if (err) {
          console.log(err);
          return;
        }
        // if there is a file in the message
        if (message.file) {
          const formData = new FormData();
          formData.append("file", message.file);
          try {
            await axios({
              method: "POST",
              url: `${process.env.REACT_APP_API_URL}/api/messages/${data.id}/upload`,
              data: formData,
              ...getFormatedToken(true),
            });
            setTimeout(() => {
              window.location.reload();
            }, 1550);
          } catch (error) {
            console.log(error);
            return;
          }
        }
        getMessages();
      }
    );
  };

  const deleteMessage = (messageId) => {
    socket.emit("feed:delete:message", { messageId: messageId }, (err) => {
      if (err) {
        console.log(err);
        return;
      }
      getMessages();
    });
  };
  return (
    <CenterLayout title="Tchat" className={false}>
      <section className="main-container">
        <SocketContext.Provider value={socketValue}>
          <MessageForm
            send={sendMessage}
            avatarPath={avatarPath}
            users={users}
          />
          {messages?.length ? (
            messages.map((message) => (
              <Message
                key={message.id}
                messageProps={message}
                avatarPath={avatarPath}
                userId={userId}
                isLiked={messageLiked.some(
                  ({ messageId }) => messageId === message.id
                )}
                messageLiked={messageLiked}
                onDeleteMessage={deleteMessage}
                users={users}
              />
            ))
          ) : (
            <p>Pas de publication récente...</p>
          )}
        </SocketContext.Provider>
      </section>
    </CenterLayout>
  );
}
