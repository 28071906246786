import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router";
import Cookies from "universal-cookie";

import { EditMode } from "../../contexts/editmode";
import PermissionContext from "../../contexts/permission";

import CenterLayout from "../../layouts/CenterLayout";
import AccountForm from "./AccountForm";
import {
  HrefBox,
  LittleCard,
} from "../../components/molecules/AdminPanel/index";

import "./Account.css";
import "./style.css";
import DefaultAccount from "./DefaultAccount";
import UsersList from "../Admin/Account/UsersList";

import { fetchOneUser } from "../../api/Users/Users";
import { ConnectionReports } from "../../api/Users/ConnectionReports";
import KeyIcon from "../../components/atoms/icons/key";
import LogoutIcon from "../../components/atoms/icons/logout";
import ImageIcon from "components/atoms/icons/image";

export default function LinksPage({ propsEditing, user }) {
  const [oneUser, setUser] = useState(null);
  const [edit, setEdit] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const { editing, setEditing } = useContext(EditMode);
  const { Role, AdminOfMainThemes, AdminOfSubThemes } =
    useContext(PermissionContext);
  const { id } = useParams();
  const cookies = new Cookies();

  const getOneUser = async () => {
    setUser(await fetchOneUser(id));
  };

  const openEditProfil = () => {
    setEditPassword(true);
  };

  useEffect(() => {
    if (propsEditing && id) {
      getOneUser();
      setEdit(true);
    }
  }, [propsEditing]);

  if (
    (user && user.Role && user.Role.some(({ name }) => name === "ADMIN")) ||
    (AdminOfMainThemes && AdminOfMainThemes.length > 0) ||
    (AdminOfSubThemes && AdminOfSubThemes.length > 0)
  ) {
    return (
      <CenterLayout title="Mon espace admin" className={false}>
        {edit ? (
          <AccountForm
            onClick={() => setEdit(false)}
            userProps={propsEditing ? oneUser : null}
          />
        ) : (
          <section className="profil_container">
            <div className="account-top">
              {editing ? (
                <div
                  className="href-box"
                  style={{ marginLeft: "0px", zIndex: 5 }}
                >
                  <div>
                    <a href="/admin/news">
                      <i className="icon-news" />
                      Editer les News
                    </a>

                    <a href="/admin/shares">
                      <i className="icon-partage" />
                      Editer les Shares
                    </a>
                    <a href="/admin/links">
                      <i className="icon-links" />
                      Editer les Links
                    </a>
                    <a href="/admin/phototheque">
                      <ImageIcon />
                      Photothèque
                    </a>
                  </div>
                </div>
              ) : (
                <>
                  <button
                    className="btn-mode"
                    onClick={(e) => {
                      setEditing(!editing);
                    }}
                  >
                    PASSER EN MODE EDITION
                  </button>
                  <h1>Rôle : {user.Role[0]?.name}</h1>
                  <button
                    className="btn-logout"
                    onClick={() => {
                      cookies.remove("::token", { path: "/" });
                      caches.keys().then((names) => {
                        names.forEach((name) => {
                          caches.delete(name);
                        });
                      });
                      document.location.reload();
                    }}
                  >
                    Déconnexion
                  </button>
                  <div className="account__action-btns">
                    <button
                      className="btn border-main btn-small gap-10 text-main bg-main-hover btn-fill d-flex text-transform-none"
                      onClick={(e) => {
                        openEditProfil();
                      }}
                    >
                      <KeyIcon />
                      Changer de mot de passe
                    </button>

                    <button
                      onClick={() => {
                        cookies.remove("::token", { path: "/" });
                        document.location.reload();
                      }}
                      className="btn border-red btn-small gap-10 text-red bg-red-hover btn-fill d-flex align-items-center text-transform-none"
                    >
                      Déconnexion
                      <LogoutIcon />
                    </button>
                  </div>
                </>
              )}
            </div>
            <div style={{ marginTop: "-7%" }}>
              {editing ? (
                Role.some(({ name }) => name === "ADMIN") && (
                  <>
                    <div style={{ display: "flex" }}>
                      <LittleCard
                        buttonText="Ajouter un utilisateur"
                        icon="icon-add-user"
                        onClick={() => setEdit(true)}
                      />
                      <LittleCard
                        buttonText="Reporting des connexions"
                        icon="icon-document"
                        onClick={async (e) => {
                          e.target.innerHTML = "Chargement...";
                          await ConnectionReports();
                          e.target.innerHTML = "Reporting des connexions";
                        }}
                      />
                    </div>
                    <UsersList />
                  </>
                )
              ) : (
                <>
                  <div style={{ paddingTop: "10%" }}></div>
                  <br />
                  <DefaultAccount
                    editPassword={editPassword}
                    setEditPassword={setEditPassword}
                  />
                </>
              )}
            </div>
          </section>
        )}
      </CenterLayout>
    );
  } else if (user && user.Role) {
    return (
      <CenterLayout title="Mon compte" className={false}>
        <div className="account-top">
          <button
            className="btn border-main btn-small gap-10 text-main bg-main-hover btn-fill d-flex text-transform-none"
            onClick={(e) => {
              openEditProfil();
            }}
          >
            <KeyIcon />
            Changer de mot de passe
          </button>

          <button
            onClick={() => {
              cookies.remove("::token", { path: "/" });
              caches.keys().then((names) => {
                names.forEach((name) => {
                  caches.delete(name);
                });
              });
              document.location.reload();
            }}
            className="btn border-red btn-small gap-10 text-red bg-red-hover btn-fill d-flex align-items-center text-transform-none"
          >
            Déconnexion
            <LogoutIcon />
          </button>
        </div>
        <div style={{ marginTop: "50px" }}>
          <DefaultAccount
            editPassword={editPassword}
            setEditPassword={setEditPassword}
          />
        </div>
      </CenterLayout>
    );
  } else {
    return <React.Fragment />;
  }
}
