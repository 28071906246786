import { useEffect, useState } from "react";
import CloseIcon from "components/atoms/icons/close";
import FilterIcon from "components/atoms/icons/filter";
import ListIcon from "components/atoms/icons/list";
import LocationIcon from "components/atoms/icons/location";
import SearchIcon from "components/atoms/icons/search";

export default function SearchBar({
  mode = "list",
  setMode = () => { },
  setSearch = () => { },
  search = "",
  list,
}) {
  const changeMode = (e, type) => {
    e.preventDefault();
    setMode(type);
  };

  const [searchValue, setSearchValue] = useState("");

  const handleSelect = (item) => {
    setTimeout(() => {
      setSearch(item.firstname + " " + item.lastname);
    }, 200);
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearch(searchValue), 500);
    return () => clearTimeout(timeOutId);
  }, [searchValue]);

  useEffect(() => {
    setSearchValue(search);
  }, [search]);

  return (
    <div className="annuaire_container_searchbar">
      <div className="annuaire__searchbar-mode-container">
        <button
          id="annuaire__searchbar-list"
          onClick={(e) => changeMode(e, "list")}
          className={mode === "list" ? "active" : ""}
        >
          <ListIcon />

          <span>Vue liste</span>
        </button>
        <button
          id="annuaire__searchbar-map"
          onClick={(e) => changeMode(e, "map")}
          className={mode === "map" ? "active" : ""}
        >
          <LocationIcon />
          <span>Vue carte</span>
        </button>
        <button
          id="annuaire__searchbar-filter"
          onClick={(e) => changeMode(e, "filter")}
          className={mode === "filter" ? "active" : ""}
        >
          <FilterIcon />
          <span>Filtres</span>
        </button>
        <div
          id="annuaire__searchbar-mobile"
          className={mode === "search" ? "active" : ""}
        >
          <button
            onClick={(e) => changeMode(e, "list")}
            className="annuaire__searchbar-mobile-close"
          >
            <CloseIcon />
          </button>
          <div>
            <button onClick={(e) => changeMode(e, "search")}>
              <SearchIcon />
            </button>
            <input
              type="text"
              placeholder="Rechercher"
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
            />
          </div>
        </div>
      </div>
      <div className="annuaire__search_desktop-container">
        <input
          type="text"
          placeholder="Rechercher"
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
        />
        <ul className="annuaire__filter-search-list" id="searchbar_list">
          {searchValue?.length > 2 &&
            list.map((item, index) => (
              <li key={index} onClick={() => handleSelect(item)}>
                {item.firstname} {item.lastname}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}
