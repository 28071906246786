import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";

//Context
import PermissionContext from "../../../contexts/permission";

// components
import QuestionPage from "../../Questions/QuestionPage";
import CenterLayout from "../../../layouts/CenterLayout";
import { HrefBox } from "../../../components/molecules/AdminPanel/";
import CKEditor from "../../../components/molecules/CKEditor/CKEditorBasic";
import { Card } from "../../../components/molecules/AdminPanel";
import "../Thematics/thematicsForm.css";

import ErrorDiv from "../../../components/molecules/ErrorsMessages/errorForm";

// api
import { fetchAllSubthemes } from "../../../api/SubThemes";
import { updateSubTheme } from "../../../api/SubThemes";
import FaqForm from "../Faq/FaqForm";
import LoadingBar from "../../../components/molecules/Loaders/Bar";
import CropImage from "../../../components/molecules/CropImage";

export default function SubThematics() {
  const [subthemesInfo, setSubthemesInfo] = useState([]);
  const [sending, setSending] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [bannerBlob, setBannerBlob] = useState(null);
  const [thumbnailBlob, setThumbnailBlob] = useState(null);
  const [uploadedThumbnail, setUploadedThumbnail] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [text, setText] = useState(null);
  const [subthemeInfo, setSubthemeInfo] = useState({
    n1: { label: "", value: "" },
    n2: { label: "", value: "" },
    n3: { label: "", value: "" },
  });
  const [subcontent, setSubcontent] = useState(null);
  const [file, setFile] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [questions, setQuestions] = useState(null);

  const { subtheme } = useParams();

  const { AdminOfSubThemes, Role } = useContext(PermissionContext);

  async function getAllSubthemes() {
    setSubthemesInfo(await fetchAllSubthemes());
  }

  useEffect(() => {
    if (subthemeInfo && subthemeInfo.Content) {
      setSubcontent(subthemeInfo.Content.html);
      setText(subthemeInfo.description);
      setThumbnailBlob(subthemeInfo.thumbnailPath);
    }
  }, [subthemeInfo]);

  useEffect(() => {
    getAllSubthemes();
  }, [subtheme]);

  useEffect(() => {
    const subthemeParsed = subtheme
      .replace(/_/g, " ")
      .replace(/-/g, "'")
      .replace(/&&/g, "/");
    let subthemeWhereName = {};
    if (subthemesInfo.length) {
      subthemeWhereName = subthemesInfo.filter(
        (item) =>
          subthemeParsed ===
          item.name
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
      );
      if (subthemeWhereName.length === 0) {
        return window.alert("page introuvable");
      }
      setSubthemeInfo(...subthemeWhereName);
    }
  }, [subthemesInfo]);

  function updateSubThemeState(key, value) {
    setSubthemeInfo((subThemeInfo) => ({
      ...subThemeInfo,
      [key]: value,
    }));
  }

  const submitSubTheme = async () => {
    const { id, n1, n2, n3 } = subthemeInfo;
    if (!thumbnail && !thumbnailBlob) {
      setSending(false);
      window.alert("Veuillez ajouter une vignette");
      return;
    }

    const res = await updateSubTheme({
      id,
      n1,
      n2,
      n3,
      banner: file,
      thumbnail: thumbnail,
      content: subcontent,
      description: text,
    });
    if (!res) {
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        setSending(false);
      }, 1509);
      return;
    }
    setError(true);
    setSending(false);
  };

  async function getBase64Url(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.addEventListener(
        "load",
        () => {
          setBannerBlob(reader.result);
        },
        false
      );
      reader.readAsDataURL(file);
    });
  }

  async function getBase64UrlThumbnail(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.addEventListener(
        "load",
        () => {
          setThumbnailBlob(reader.result);
        },
        false
      );
      reader.readAsDataURL(file);
    });
  }

  const isValid = AdminOfSubThemes.map((step) => {
    if (step.id === subthemeInfo.id) {
      return true;
    } else return false;
  });
  const isValidRole = Role.map((step) => {
    if (step.name === "ADMIN") {
      return true;
    } else return false;
  });


  const handleSelectEdit = (question) => {
    if (!question) return setSelectedQuestion(null);
    setSelectedQuestion(question);
  }

  if (!isValid.includes(true) && !isValidRole.includes(true)) {
    return (
      <CenterLayout title="Sous-thématique">
        <h1
          style={{
            color: "var(--" + subthemeInfo.color + ")",
            paddingTop: "15vh",
          }}
          className="title"
        >
          {subthemeInfo && subthemeInfo.name}
        </h1>
        <Card>
          <div
            style={{
              fontFamily: "var(--cera-regular)",
              margin: "60px 20px",
              textAlign: "center",
            }}
          >
            Vous n'avez pas accès à la modification de cette sous-thèmatique
          </div>
        </Card>
      </CenterLayout>
    );
  }
  return (
    <CenterLayout title="Sous-thématique">
      <h1
        style={{
          color: "var(--" + subthemeInfo.color + ")",
          paddingTop: "15vh",
        }}
        className="title"
      >
        {subthemeInfo && subthemeInfo.name}
      </h1>
      <div>
        <div className="before-card" style={{ paddingTop: "0" }}>
          <Card>
            <div className="admin-them-form-body">
              <h1 className="title">Modifier le Chapô</h1>

              <textarea
                className="admin-input"
                type="text"
                placeholder="Votre text"
                onChange={(e) => setText(e.target.value)}
                defaultValue={subthemeInfo.description}
                required
              />
              <h1 className="title">Modifier les chiffres clés</h1>
              <div className="admin-them-form-container">
                <div>
                  <input
                    type="text"
                    className="admin-input"
                    value={subthemeInfo.n1.value}
                    placeholder="ex: 30"
                    onChange={({ target }) =>
                      updateSubThemeState("n1", {
                        label: subthemeInfo.n1.label,
                        value: target.value,
                      })
                    }
                  />
                  <input
                    type="text"
                    className="admin-input"
                    value={subthemeInfo.n1.label}
                    placeholder="ex: Titre"
                    onChange={({ target }) =>
                      updateSubThemeState("n1", {
                        label: target.value,
                        value: subthemeInfo.n1.value,
                      })
                    }
                  />
                </div>
                <div>
                  <input
                    type="text"
                    className="admin-input"
                    value={subthemeInfo.n2.value}
                    placeholder="ex: 30"
                    onChange={({ target }) =>
                      updateSubThemeState("n2", {
                        label: subthemeInfo.n2.label,
                        value: target.value,
                      })
                    }
                  />
                  <input
                    type="text"
                    className="admin-input"
                    value={subthemeInfo.n2.label}
                    placeholder="ex: Titre"
                    onChange={({ target }) =>
                      updateSubThemeState("n2", {
                        label: target.value,
                        value: subthemeInfo.n2.value,
                      })
                    }
                  />
                </div>
                <div>
                  <input
                    type="text"
                    className="admin-input"
                    value={subthemeInfo.n3.value}
                    placeholder="ex: 30"
                    onChange={({ target }) =>
                      updateSubThemeState("n3", {
                        label: subthemeInfo.n3.label,
                        value: target.value,
                      })
                    }
                  />
                  <input
                    type="text"
                    className="admin-input"
                    value={subthemeInfo.n3.label}
                    placeholder="ex: Titre"
                    onChange={({ target }) =>
                      updateSubThemeState("n3", {
                        label: target.value,
                        value: subthemeInfo.n3.value,
                      })
                    }
                  />
                </div>
              </div>
              <div style={{ margin: "20px" }}>
                <label
                  className="upload-style"
                  htmlFor="upload-file2"
                  style={{
                    minHeight: "200px",
                    maxWidth: "400px",
                    margin: "auto",
                    backgroundImage: `linear-gradient(0deg, #161b4b2b 0%, #161b4b2b 100%), url("${thumbnailBlob
                      ? thumbnailBlob
                      : subthemeInfo &&
                      subthemeInfo.thumbnailPath &&
                      subthemeInfo.thumbnailPath
                      }")`,
                  }}
                >
                  <i className="icon-cloud" style={{ fontSize: "2rem" }} />
                  <p className="btn btn-small bg-main">
                    {thumbnail
                      ? "Changer la vignette"
                      : "Selectionnez une vignette"}
                  </p>
                </label>
                <input
                  id="upload-file2"
                  type="file"
                  onChange={(e) => {
                    setUploadedThumbnail(e.target.files[0]);
                    setThumbnail(e.target.files[0]);
                  }}
                  name="thumbnail"
                />
                {thumbnail ? (
                  <CropImage
                    setImage={setThumbnail}
                    setImageBlob={setThumbnailBlob}
                    src={thumbnail}
                    ratio={4 / 3}
                  />
                ) : (
                  ""
                )}
                <br />
                <p className="text-center default-p">
                  Recommandé: 400px x 300px (5MO max)
                </p>
                <br />
              </div>
              <div style={{ margin: "20px" }}>
                <label
                  className="upload-style"
                  htmlFor="upload-file"
                  style={{
                    minHeight: "200px",
                    maxWidth: "1280px",
                    margin: "auto",
                    backgroundImage: `linear-gradient(0deg, #161b4b2b 0%, #161b4b2b 100%), url("${bannerBlob
                      ? bannerBlob
                      : subthemeInfo &&
                        subthemeInfo.bannerImgPath &&
                        subthemeInfo.bannerImgPath.startsWith("/")
                        ? subthemeInfo.bannerImgPath
                        : "/" + subthemeInfo.bannerImgPath
                      }")`,
                  }}
                >
                  <i className="icon-cloud" style={{ fontSize: "2rem" }} />
                  <p className="btn btn-small bg-main">
                    {file ? "Changer la bannière" : "Selectionnez une bannière"}
                  </p>
                </label>
                <input
                  id="upload-file"
                  type="file"
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                  }}
                  name="file"
                />
                {file ? (
                  <CropImage
                    setImage={setFile}
                    setImageBlob={setBannerBlob}
                    src={file}
                    ratio={16 / 9}
                  />
                ) : (
                  ""
                )}
                <br />
                <p className="text-center default-p">
                  Recommandé: 1280px x 720px (5MO max)
                </p>
                <br />
              </div>
              <h1 className="title">Modifier le contenu</h1>
              <CKEditor
                noSave="true"
                placeholder={subcontent ? `${subcontent}` : ""}
                ckeditorCallback={setSubcontent}
              />
            </div>
            <button
              className="save-btn"
              onClick={(e) => {
                e.preventDefault();
                setSending(true);
                submitSubTheme();
              }}
            >
              Enregistrer
            </button>

            {sending && (
              <>
                <br />
                <br />
                <LoadingBar done={false} />
              </>
            )}
            {error && <ErrorDiv message="Erreur: un problème est survenu..." />}
            {success && (
              <div
                style={{
                  backgroundColor: "var(--green-color)",
                  color: "white",
                  padding: 10,
                  fontFamily: "var(--cera-bold)",
                  textAlign: "center",
                  marginTop: 10,
                }}
              >
                Changements enregistrés avec succès !
              </div>
            )}
          </Card>
        </div>

        <div
          className="thematic__faq-container"
          style={{
            display: "flex",
            alignItems: "flex-start",
            marginBottom: "20px",
            maxWidth: "900px",
            margin: "auto",
            gap: "20px",
          }}
        >
          <div style={{ width: "60%" }}>
            <FaqForm
              filter={subthemeInfo && subthemeInfo.id}
              isSubTheme={true}
              isValid={questions >= 3 ? false : true}
              selectedQuestion={selectedQuestion}
              onCancel={() => {
                setSelectedQuestion(null);
              }}
            />
          </div>
          <QuestionPage
            filter={subthemeInfo.id && [`sub_theme:${subthemeInfo.id}`]}
            onEdit={handleSelectEdit}
            callback={setQuestions}
            selectedQuestion={selectedQuestion}
          />
        </div>

        <HrefBox />
      </div>
    </CenterLayout>
  );
}
