import React, { useState, useEffect } from "react";
import "./subtheme.css";
import "./subcontent.css";

// components
import DefaultLayout from "../../layouts/DefaultLayout";
import SubThemePage from "./SubThemePage";

export default function Subtheme() {
  return (
    <DefaultLayout>
      <SubThemePage />
    </DefaultLayout>
  );
}
