const users = [
  {
    id: 1,
    avatar: null,
    name: "John Doe",
    job: "Développeur",
    entity: "Axione",
    branch: "Bouygues Construction",
    banner: null,
    assignments: [
      "Recrutement",
      "Formation",
      "test",
      "test",
      "test",
      "test",
      "test",
      "test",
      "test",
    ],
    interventions: ["Essone", "Palaiseau"],
  },
  {
    id: 2,
    avatar: null,
    name: "Alexandra Lallemand",
    job: "Responsable Développement RH",
    entity: "Axione",
    branch: "BOUYGUES CONSTRUCTION",
    banner: null,
    assignments: ["Recrutement", "Formation"],
    interventions: ["Essone", "Palaiseau"],
  },
  {
    id: 2,
    avatar: null,
    name: "Alexandra Lallemand",
    job: "Responsable Développement RH",
    entity: "Axione",
    branch: "BOUYGUES CONSTRUCTION",
    banner: null,
    assignments: ["Recrutement", "Formation"],
    interventions: ["Essone", "Palaiseau"],
  },
  {
    id: 2,
    avatar: null,
    name: "Alexandra Lallemand",
    job: "Responsable Développement RH",
    entity: "Axione",
    branch: "BOUYGUES CONSTRUCTION",
    banner: null,
    assignments: ["Recrutement", "Formation"],
    interventions: ["Essone", "Palaiseau"],
  },
  {
    id: 2,
    avatar: null,
    name: "Alexandra Lallemand",
    job: "Responsable Développement RH",
    entity: "Axione",
    branch: "BOUYGUES CONSTRUCTION",
    banner: null,
    assignments: ["Recrutement", "Formation"],
    interventions: ["Essone", "Palaiseau"],
  },
  {
    id: 2,
    avatar: null,
    name: "Alexandra Lallemand",
    job: "Responsable Développement RH",
    entity: "Axione",
    branch: "BOUYGUES CONSTRUCTION",
    banner: null,
    assignments: ["Recrutement", "Formation"],
    interventions: ["Essone", "Palaiseau"],
  },
  {
    id: 2,
    avatar: null,
    name: "Alexandra Lallemand",
    job: "Responsable Développement RH",
    entity: "Axione",
    branch: "BOUYGUES CONSTRUCTION",
    banner: null,
    assignments: ["Recrutement", "Formation"],
    interventions: ["Essone", "Palaiseau"],
  },
];

export default users;
