import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ThemeIcon } from "../molecules/IconTheme";
import { deleteLinks } from "../../api/Links";

import PermissionContext from "../../contexts/permission";

import { EditMode } from "../../contexts/editmode";

const Item = ({ item, deleteOneLink }) => {
  const { editing } = useContext(EditMode);
  const { Role, AdminOfSubThemes, AdminOfMainThemes } =
    useContext(PermissionContext);

  const { id, title, image, description, url, MainThemes, SubThemes } = item;

  const isValideSub = SubThemes.map((step) => {
    if (AdminOfSubThemes.some(({ id }) => id === step.id)) {
      return true;
    } else {
      return false;
    }
  });
  const isValideMain = MainThemes.map((step) => {
    if (AdminOfMainThemes.some(({ id }) => id === step.id)) {
      return true;
    } else {
      return false;
    }
  });

  return (
    <div className="links__item">
      <a target="_blank" href={url}>
        <div className="links__content">
          <div className="links__title">
            {image ? (
              <img
                src={image}
                alt={description}
              />
            ) : (
              <h2>{title}</h2>
            )}
          </div>
          <div className="links__spacer" />
          <p>{description}</p>
        </div>
        <div className="links__footer">
          <div className="links__item-thematics">
            <p>
              <ThemeIcon theme={MainThemes[0]} />
              {MainThemes.length > 1 && `+${MainThemes.length - 1}`}
            </p>
            {MainThemes.length > 1 && (
              <div>
                {MainThemes.map((thematic, index) => {
                  return (
                    index > 0 && <ThemeIcon key={index} theme={thematic} />
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </a>
      {(Role.length > 0 && Role.some(({ name }) => name === "ADMIN")) ||
        (AdminOfSubThemes.length > 0 && isValideSub.includes(true)) ||
        isValideMain.includes(true) ? (
        editing && (
          <div className="links__edit_container">
            <Link to={"/admin/links/" + id}>
              <button className="links__edit_button">Modifier</button>
            </Link>
            <div
              className="links__edit_trash"
              onClick={() => {
                if (
                  window.confirm(
                    `Êtes vous sûr de vouloir suprimer le lien :  ${title} ?`
                  )
                ) {
                  deleteOneLink(id);
                }
              }}
            >
              <i className="icon-trash" />
            </div>
          </div>
        )
      ) : (
        <React.Fragment />
      )}
    </div>
  );
};

export default Item;
