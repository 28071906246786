import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { fetchAllEvents } from "../../api/Events/Events";
import Item from "./Item";
import moment from "moment";

import DropdownIcons from "../molecules/Thematics/DropdownIcons";
import NotFound from "../molecules/ErrorsMessages/NotFound";
import searchNearestDate from "../../helpers/searchNearestDate";
import FilterEventByDate from "../molecules/Cards/EventsHome";

export default function HomeEventsContent() {
  const [events, setEvents] = useState([]);

  async function getAllEvents() {
    setEvents(await fetchAllEvents([]));
  }

  useEffect(() => {
    getAllEvents();
  }, []);

  return (
    <div className="home__events-container">
      <h1 className="home__events-title">Events</h1>
      {events
        ? events.map((event, index) => {
            return event.isFirst ? (
              <Link to={`/events/${event.id}`}>
                <div
                  className="home__first-event"
                  style={{
                    backgroundImage: event.logoImgPath.length
                      ? `url(${event.logoImgPath})`
                      : "#fff",
                  }}
                >
                  {searchNearestDate(event.EventSession)}
                </div>
              </Link>
            ) : (
              ""
            );
          })
        : ""}
      <div className="home__events-main">
        {events ? <FilterEventByDate events={events} /> : ""}
      </div>
    </div>
  );
}
