import { useState, useEffect, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import HomeLayout from "../../layouts/HomeLayout";
import "./style.css";
import HomeNewsContent from "../../components/ContentListNews/HomeNewsContent";
import HomeEventsContent from "../../components/Events/HomeEventsContent";
import HomeSharesContent from "../../components/ContentListShares/HomeSharesContent";
import HomeLinksContent from "../../components/Links/HomeLinksContent";

import ThematicsList from "../../components/molecules/Thematics/List";
import LastMessage from "../../components/molecules/Feed/LastMessage";
import NewsComersList from "../../components/molecules/NewComers/List";
import HRTrendsList from "../../components/molecules/HRTrends/HRTrends";
import DefaultModal from "../../components/molecules/Modals/Default";
import { fetchDashboard } from "../../api/Dashboard";
import { EditMode } from "../../contexts/editmode";
import { HomeModal } from "../../contexts/homepopup";

export default function Home() {
  let history = useHistory();

  const [dashboard, setDashboard] = useState();
  const { editing } = useContext(EditMode);
  const { isOpen, setIsOpen } = useContext(HomeModal);

  async function getDashboard() {
    setDashboard(await fetchDashboard());
  }

  useEffect(() => {
    if (editing) {
      history.push("/admin");
    }
    getDashboard();
  }, []);

  return (
    <>
      <HomeLayout>
        <div className="home__container">
          <div className="home__main-container">
            <HomeNewsContent />
            {dashboard && dashboard.n1 && (
              <div className="home__numbers-container">
                <div
                  className="home__main-number"
                  style={{
                    backgroundImage: `url(${dashboard.bannerImgPath})`,
                  }}
                >
                  <h2>
                    {/* {new Intl.NumberFormat("fr-FR").format(dashboard.n1.value)} */}
                    {dashboard.n1.value}
                  </h2>
                  <p>{dashboard.n1.label}</p>
                </div>
                <div className="home__secondary-numbers">
                  <div>
                    <h2>
                      {/* {new Intl.NumberFormat("fr-FR").format(
                        dashboard.n2.value
                      )} */}
                      {dashboard.n2.value}
                    </h2>
                    <p>{dashboard.n2.label}</p>
                  </div>
                  <div>
                    <h2>
                      {/* {new Intl.NumberFormat("fr-FR").format(
                        dashboard.n3.value
                      )} */}
                      {dashboard.n3.value}
                    </h2>
                    <p>{dashboard.n3.label}</p>
                  </div>
                  <div>
                    <h2>
                      {/* {new Intl.NumberFormat("fr-FR").format(
                        dashboard.n4.value
                      )} */}
                      {dashboard.n4.value}
                    </h2>
                    <p>{dashboard.n4.label}</p>
                  </div>
                </div>
              </div>
            )}
            <HomeEventsContent />
            <HomeSharesContent />
            <HomeLinksContent />
          </div>
          <div className="home__right-bar">
            <ThematicsList />
            <LastMessage />
            <NewsComersList />
            <HRTrendsList limit={3} isHome={true} />
          </div>
        </div>
      </HomeLayout>
      <DefaultModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        className="welcome-modal"
      >
        {/* <img id="fixed-image" src="/assets/img/Jean-Manuel_Soussan.png" /> */}
        <h1>
          Welcome
          <br />
          on <img src="/assets/icons/Footer/logo_HR_team.svg" alt="" />
        </h1>
        <div className="wlecome-modal__content">
          <br />
          <iframe
            src="https://www.youtube-nocookie.com/embed/m7_loaKHoQw"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            style={{ width: "100%", height: "250px" }}
          ></iframe>
          <br />
          <br />
          <br />
          <p>
            {/* <button className="btn bg-main" onClick={() => setOpenModal(false)}>

              ALLER SUR LA PLATEFORME
            </button> */}
            {/* <Link
              className="btn bg-main"
              to={`/news/61`}
              style={{ display: "block", margin: "auto" }}
            >
              Lire l'édito
            </Link> */}
          </p>
        </div>
      </DefaultModal>
    </>
  );
}
