import axios from "axios";
import getFormatedToken from "../../helpers/getFormatedToken";

function format(array) {
  return array.reduce(
    (accumulator, value) => {
      const [key, id] = value.split(":");

      return {
        ...accumulator,
        [key]: [...(accumulator[key] || []), id],
      };
    },
    { MainThemes: [], SubThemes: [] }
  );
}
function formatPUT(array) {
  return array.reduce(
    (accumulator, value) => {
      const [key, id] = value.split(":");

      return {
        ...accumulator,
        [key]: [...(accumulator[key] || []), Number(id)],
      };
    },
    { MainThemes: [], SubThemes: [] }
  );
}

export async function addUser({
  firstname,
  lastname,
  welcome,
  email,
  job,
  wingzy,
  branchId,
  permissions,
  referentGroup,
  referentMetier,
  isSuperAdmin,
}) {
  const UserData = {
    firstname,
    lastname,
    welcome,
    email,
    job,
    wingzy,
    branchId: Number(branchId),
    Admin: format(permissions),
    GroupReferent: format(referentGroup),
    Referent: format(referentMetier),
  };
  if (isSuperAdmin) {
    UserData["Roles"] = ["ADMIN"];
  } else {
    UserData["Roles"] = ["USER"];
  }
  try {
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/authentication/invite`,
      data: UserData,
      ...getFormatedToken(false, true),
    });
  } catch (error) {
    // TODO: Handle error properly
    // console.log(error);
    return error;
  }
}

export async function updateUser({
  id,
  firstname,
  lastname,
  email,
  job,
  wingzy,
  branchId,
  permissions,
  referentGroup,
  referentMetier,
  isSuperAdmin,
}) {
  const UserData = {
    firstname,
    lastname,
    email,
    job,
    wingzy,
    branchId: Number(branchId),
    Admin: formatPUT(permissions),
    GroupReferent: formatPUT(referentGroup),
    Referent: formatPUT(referentMetier),
  };
  if (isSuperAdmin) {
    UserData["Roles"] = ["ADMIN"];
  } else {
    UserData["Roles"] = ["USER"];
  }

  try {
    await axios({
      method: "PUT",
      url: `${process.env.REACT_APP_API_URL}/api/users/${id}`,
      data: UserData,
      ...getFormatedToken(false, true),
    });
  } catch (error) {
    // TODO: Handle error properly
    // console.log(error);
    return error;
  }
}
