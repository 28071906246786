import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GetAllUsers } from "../../../api/Users/GetAllUsers";
import { deleteOneUser } from "../../../api/Users/Users";
import { ConnectionReports } from "../../../api/Users/ConnectionReports";
import { ThemeIcon } from "../../../components/molecules/IconTheme";
import Search from "../../../components/Search/SearchFilter";

import "./UsersList.css";

export default function UsersList() {
  const [users, setUsers] = useState();
  const [searchFilter, setSearchFilter] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);

  async function AllUsers() {
    setUsers(await GetAllUsers());
  }

  useEffect(() => {
    AllUsers();
    const theadUsersList = document.getElementById("theadUsersList");
    const tableUsers = document.querySelector(
      ".users_list_container:last-child"
    );

    tableUsers.addEventListener("scroll", function (e) {
      if (e.target.scrollTop > 0) {
        theadUsersList.style.transform = `translateY(${e.target.scrollTop}px)`;
        theadUsersList.style.backgroundColor = `#e7e7f3`;
      } else {
        theadUsersList.style.backgroundColor = `transparent`;
        theadUsersList.style.transform = `translateY(0px)`;
      }
    });
  }, []);

  useEffect(() => {
    setFilteredUsers(
      users &&
        users.filter(
          ({ firstname, lastname }) =>
            (firstname && firstname.toLowerCase().includes(searchFilter)) ||
            (lastname && lastname.toLowerCase().includes(searchFilter))
        )
    );
  }, [searchFilter, users]);

  return (
    <>
      <div className="users_list_container">
        <Search
          onChange={(filter) => setSearchFilter(filter.toLowerCase())}
          className={"search__reduced"}
        />
      </div>
      <div className="users_list_container">
        <table className="users_list-table">
          <thead id="theadUsersList">
            <tr>
              <th></th>
              <th>Nom Prénom</th>
              <th>Admins</th>
              <th>Référent Groupe</th>
              <th>Référent Métier</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers &&
              filteredUsers
                .sort(function (a, b) {
                  return new Date(b.createdAt) - new Date(a.createdAt);
                })
                .map((user, index) => (
                  <tr style={{ borderBottom: "1px solid #e9e9e9" }}>
                    <td>
                      {user.avatarPath ? (
                        <img
                          src={user.avatarPath}
                          alt={`#${index}`}
                          className="users_list-avatar"
                        />
                      ) : (
                        <img
                          src={"assets/img/avatar.svg"}
                          alt={"profile"}
                          className="users_list-avatar"
                        />
                      )}
                    </td>
                    <td>
                      {user.lastname} {user.firstname}
                    </td>
                    <td>
                      {user.Role.length > 0 &&
                        user.Role.map((role) => (
                          <p style={{ padding: "5px" }}>{role.name}</p>
                        ))}
                      {user.AdminOfMainThemes.map((theme) => (
                        <p className="users_list_p">
                          <ThemeIcon theme={theme} />
                          {theme.name}
                        </p>
                      ))}
                      {user.AdminOfSubThemes.map((sub) => (
                        <p className="users_list_p">
                          <div
                            style={{
                              width: "10px",
                              margin: "5px",
                            }}
                            className={`account_bullet-point bg-${sub.color}`}
                          ></div>
                          {sub.name}
                        </p>
                      ))}
                    </td>
                    <td>
                      {user.GroupReferentOfMainThemes.map((theme) => (
                        <p className="users_list_p">
                          <ThemeIcon theme={theme} />
                          {theme.name}
                        </p>
                      ))}
                      {user.GroupReferentOfSubThemes.map((sub) => (
                        <p className="users_list_p">
                          <div
                            style={{
                              width: "10px",
                              margin: "0px 10px 0px 5px",
                            }}
                            className={`account_bullet-point bg-${sub.color}`}
                          />
                          {sub.name}
                        </p>
                      ))}
                    </td>
                    <td>
                      {user.ReferentOfMainThemes.map((theme) => (
                        <p className="users_list_p">
                          <ThemeIcon theme={theme} />
                          {theme.name}
                        </p>
                      ))}
                      {user.ReferentOfSubThemes.map((sub) => (
                        <p className="users_list_p">
                          <div
                            style={{ width: "20px", margin: "5px" }}
                            className={`account_bullet-point bg-${sub.color}`}
                          ></div>
                          {sub.name}
                        </p>
                      ))}
                    </td>
                    <td>
                      <a href={"/account/" + user.id}>
                        <i
                          className={"icon-write-bubble"}
                          style={{
                            color: "var(--main-color)",
                            fontSize: "20px",
                            cursor: "pointer",
                            textDecoration: "none",
                          }}
                        />
                      </a>
                      <i
                        className="icon-trash"
                        style={{
                          color: "red",
                          fontSize: "20px",
                          marginLeft: "20px",
                          cursor: "pointer",
                          textDecoration: "none",
                        }}
                        onClick={() => {
                          if (
                            window.confirm(
                              `Êtes vous sûr de vouloir suprimer l'utilisateur :  ${user.email}?`
                            )
                          ) {
                            deleteOneUser(user.id);
                            setUsers((users) =>
                              users.filter(({ id }) => id !== user.id)
                            );
                          }
                        }}
                      />
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
