import "./search.css";
import { useState, useEffect } from "react";

function Search({
  onChange,
  className = "search__block",
  value = "",
  placeholder,
}) {
  useEffect(() => {
    if (value && value !== "") {
      onChange(value);
    }
  }, []);

  return (
    <input
      className={className}
      defaultValue={value}
      onChange={(event) => onChange(event.target.value)}
      name="search"
      type="text"
      autoComplete="off"
      placeholder={placeholder ? placeholder : ""}
    />
  );
}

export default Search;
