import React, { createContext } from "react";
import Cookies from "universal-cookie";

export const TokenContext = createContext({
  token: null,
  getTokenFromCookies: () => {
    const cookies = new Cookies();
    return cookies.get("::token");
  },
});
