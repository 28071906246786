// import ReactGA from "react-ga";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function useAnalytics() {
  const { trackPageView } = useMatomo();
  let location = useLocation();

  // useEffect(() => {
  //   const { analytics } = JSON.parse(localStorage.getItem("cookiegdpr"));

  //   if (analytics) {
  //     // trackPageView();
  //   }
  // }, [location, trackPageView]);

  // const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS;
  // ReactGA.initialize(trackingId, { debug: true });
  // ReactGA.pageview(window.location.pathname + window.location.search);
}
