import React, { useState, useEffect, useContext } from "react";

import { Link } from "react-router-dom";
import moment from "moment";

import { EditMode } from "../../contexts/editmode";
import Theme from "../molecules/Thematics/Icons";
import searchNearestDate from "../../helpers/searchNearestDate";

import PermissionContext from "../../contexts/permission";
import {
  fetchIsSubscribedEvent,
  sendSubscribeEvent,
  sendUnSubscribeEvent,
} from "../../api/Events/Event";

export default function Item({
  event: {
    id,
    title,
    text,
    logoImgPath,
    hashtags,
    MainThemes,
    SubThemes,
    Content,
    EventSession,
  },
  isFirst,
  deleteOneEvent,
}) {
  const [isSubscribed, setIsSubscribed] = useState(null);
  const { editing } = useContext(EditMode);
  const { Role, AdminOfSubThemes, AdminOfMainThemes } =
    useContext(PermissionContext);

  const isValideSub = SubThemes.map((step) => {
    if (AdminOfSubThemes.some(({ id }) => id === step.id)) {
      return true;
    } else {
      return false;
    }
  });
  const isValideMain = MainThemes.map((step) => {
    if (AdminOfMainThemes.some(({ id }) => id === step.id)) {
      return true;
    } else {
      return false;
    }
  });
  async function getIsSubscribed(id) {
    setIsSubscribed(null);
    setIsSubscribed(await fetchIsSubscribedEvent(id));
  }

  useEffect(() => {
    getIsSubscribed(id);
  }, [id]);

  const subscribe = async () => {
    setIsSubscribed(null);
    if (isSubscribed) {
      await sendUnSubscribeEvent(id);
      setIsSubscribed(false);
    } else {
      await sendSubscribeEvent(id);
      setIsSubscribed(true);
    }
  };

  return isFirst ? (
    <article className="events__item">
      <Link to={`/events/${id}`}>
        <header
          style={{
            backgroundImage: `url(${logoImgPath})`,
          }}
        >
          {searchNearestDate(EventSession)}
        </header>
      </Link>
      <main>
        <div className="events__thematics">
          {MainThemes.map((MainThemes, index) => (
            <Theme key={index} theme={MainThemes} />
          ))}
        </div>
        <div className="events__item__title">
          <h1>{title}</h1>
          {isSubscribed === null ? (
            <button>
              <div className="loader" style={{ margin: "auto" }}></div>
            </button>
          ) : isSubscribed === true ? (
            <button onClick={subscribe}>
              <i className="icon-remove-notification text-main"></i>
            </button>
          ) : (
            <button onClick={subscribe}>
              <i className="icon-add-notification text-main"></i>
            </button>
          )}
        </div>
        {hashtags.map((item, index) => (
          <span key={index}>#{item.replace(/,/g, " #")}</span>
        ))}
        <p>{text}</p>
        {(Role.length > 0 && Role.some(({ name }) => name === "ADMIN")) ||
          (AdminOfSubThemes.length > 0 && isValideSub.includes(true)) ||
          isValideMain.includes(true) ? (
          editing && (
            <div className="links__edit_container">
              <Link to={"/admin/events/" + id}>
                <button className="links__edit_button">Modifier</button>
              </Link>
              <div
                className="links__edit_trash"
                onClick={() => {
                  if (
                    window.confirm(
                      `Êtes vous sûr de vouloir suprimer l'event :  ${title}?`
                    )
                  ) {
                    deleteOneEvent(id);
                  }
                }}
              >
                <i className="icon-trash" />
              </div>
              <div className="published_FIRST">
                {Content && Content.state === "DRAFT" ? (
                  <div className="published_align">
                    <div
                      className="account_bullet-point bg-grey"
                      style={{ marginRight: "5px" }}
                    ></div>
                    <p>Brouilon</p>
                  </div>
                ) : Content && Content.state === "ARCHIVED" ? (
                  <p className="published_align">
                    <div
                      className="account_bullet-point bg-red"
                      style={{ marginRight: "5px" }}
                    ></div>
                    Archivé
                  </p>
                ) : (
                  <div className="published_align">
                    <div
                      className="account_bullet-point bg-green"
                      style={{ marginRight: "5px" }}
                    ></div>
                    <p>Publié</p>
                  </div>
                )}
              </div>
            </div>
          )
        ) : (
          <React.Fragment />
        )}
      </main>
    </article>
  ) : (
    <div className="admin-panel-edit-background">
      <Link to={`/events/${id}`}>
        <article className="events__item">
          <header
            style={{
              backgroundImage: `url(${logoImgPath})`,
            }}
          >
            {searchNearestDate(EventSession)}
          </header>
          <main>
            <div>
              {MainThemes.map((MainThemes, index) => (
                <Theme key={index} theme={MainThemes} />
              ))}
            </div>
            <h1>{title}</h1>
            {hashtags.map((item, index) => (
              <span key={index}>#{item.replace(/,/g, " #")}</span>
            ))}
            <p>{text}</p>
          </main>
        </article>
      </Link>
      {(Role.length > 0 && Role.some(({ name }) => name === "ADMIN")) ||
        (AdminOfSubThemes.length > 0 && isValideSub.includes(true)) ||
        isValideMain.includes(true) ? (
        editing && (
          <div className="links__edit_container">
            <Link to={"/admin/events/" + id}>
              <button className="links__edit_button">Modifier</button>
            </Link>
            <div
              className="links__edit_trash"
              onClick={() => {
                if (
                  window.confirm(
                    `Êtes vous sûr de vouloir suprimer l'event :  ${title}?`
                  )
                ) {
                  deleteOneEvent(id);
                }
              }}
            >
              <i className="icon-trash" />
            </div>
            <div className="published">
              {Content && Content.state === "DRAFT" ? (
                <div className="published_align">
                  <div
                    className="account_bullet-point bg-grey"
                    style={{ marginRight: "5px" }}
                  ></div>
                  Brouillon
                </div>
              ) : Content && Content.state === "ARCHIVED" ? (
                <div className="published_align">
                  <div
                    className="account_bullet-point bg-red"
                    style={{ marginRight: "5px" }}
                  ></div>
                  Archivé
                </div>
              ) : (
                <div className="published_align">
                  <div
                    className="account_bullet-point bg-green"
                    style={{ marginRight: "5px" }}
                  ></div>
                  Publié
                </div>
              )}
            </div>
          </div>
        )
      ) : (
        <React.Fragment />
      )}
    </div>
  );
}
