import axios from "axios";
import getFormatedToken from "../../helpers/getFormatedToken";

export async function fetchOneUser(id) {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/users/${id}`,
    getFormatedToken()
  );

  const { data } = response.data;
  return data.user;
}

export const deleteOneUser = async (id) => {
  if (!id) {
    return;
  }
  const reponse = await axios({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_URL}/api/users/${id}`,
    ...getFormatedToken(),
    validateStatus: () => true,
  });
};
