import axios from "axios";
const convert = require("xml-js");

export async function fetchAllOpportunities() {
  try {
    const response = await axios.get(
      "https://mobyclic.bouygues.com/wp-json/wp/v2/offre?filiere_ts=883&_embed=1"
    );

    let randomIndex = [];

    if (!response.data.length) return [];

    while (randomIndex.length < 3) {
      const randomNumber = Math.floor(Math.random() * response.data.length);
      if (!randomIndex.includes(randomNumber)) {
        randomIndex.push(randomNumber);
      }
    }

    let opportunities = [];

    for (const key in response.data) {
      if (randomIndex.includes(Number(key))) {
        //console.log("response -> ", response.data[key]);
        opportunities.push({
          title: response.data[key].title.rendered,
          link: response.data[key].link,
          entity: response.data[key]._embedded["wp:term"][1][0].name || "",
          localisation: `${
            response.data[key]._embedded["wp:term"][2][1]
              ? response.data[key]._embedded["wp:term"][2][1].name + " "
              : ""
          }${
            response.data[key]._embedded["wp:term"][2][0].name
              ? response.data[key]._embedded["wp:term"][2][0].name
              : ""
          }`,
        });
      }
    }
    //console.log("opportunities -> ", opportunities);

    return opportunities;
  } catch (error) {
    // TODO: Handle error properly
    // console.log(error);
  }
}
