import { MapContainer, TileLayer } from "react-leaflet";
import ShowElement from "./showElement";
import "./map.style.css";
import { useEffect, useState } from "react";
import List from "pages/Annuaire/_components/content/list";
import joinClasses from "helpers/joinClasses";
import CloseIcon from "components/atoms/icons/close";
import { getReversedGeolocation } from "api/Geolocation/editGeolocation";
import { sendFilters } from "api/Annuaire/sendFilters";

export default function Map({ data, onSeletedUser = () => { } }) {
  const [userFocused, setUserFocused] = useState([]);
  const [closeHandler, setCloseHandler] = useState(null);
  const [geolocationReversed, setGeolocationReversed] = useState(null);

  useEffect(() => {
    handleReverse();
  }, [data]);

  const handleFilters = async () => {
    const filters = localStorage.getItem("hraFilter");
    const filtersParsed = JSON.parse(filters);
    if (filtersParsed?.skip) {
      delete filtersParsed.skip;
    }
    if (filtersParsed?.take) {
      delete filtersParsed.take;
    }
    filtersParsed.noSkipping = true;
    let temp = [];
    const users = await sendFilters(filtersParsed);
    users?.forEach((user) => temp.push(user.id));
    return temp;
  };

  const handleReverse = async () => {
    const users = await handleFilters();
    const data = await getReversedGeolocation(users);
    setGeolocationReversed(data);
  };
  return (
    <div className="leaflet-container">
      <MapContainer
        center={[48.7317039, 2.2914833]}
        zoom={7}
        scrollWheelZoom={true}
        className="z-20"
        minZoom={3}
        maxZoom={14}
        ren
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png"
        />
        {geolocationReversed?.length && (
          <ShowElement
            data={geolocationReversed}
            callBack={(result) => {
              if (result == "close") {
                setCloseHandler(true);
                return;
              }
              setCloseHandler(null);
              const userTemp = [];
              result?.forEach((element) => {
                userTemp.push(element.properties);
              });
              setUserFocused(userTemp);
            }}
          />
        )}
      </MapContainer>

      {/* List appear on click of max zoomed cluster */}
      <div
        className={joinClasses(
          "map__user-focused-list",
          !closeHandler &&
          userFocused &&
          userFocused[0]?.firstname &&
          "open-map__user-focused-list"
        )}
      >
        <span
          onClick={() => {
            setCloseHandler("close");
          }}
        >
          <CloseIcon />
        </span>
        <List list={userFocused} onSeletedUser={onSeletedUser} />
      </div>
    </div>
  );
}
