import React, { useState, useEffect } from "react";
import EventPage from "../../Events/EventPage";

import CenterLayout from "../../../layouts/CenterLayout";
import { fetchOneEvent } from "../../../api/Events/Events";

// molecules
import { LittleCard } from "../../../components/molecules/AdminPanel/index";

import EventsForm from "./EventsForm";

import "../../Shares/Shares.css";
import { useParams } from "react-router";

export default function Shares({ propsEditing }) {
  const [edit, setEdit] = useState(false);
  const [event, setEvent] = useState(null);

  const { id } = useParams();

  const getOneShare = async () => {
    setEvent(await fetchOneEvent(id));
  };

  useEffect(() => {
    if (propsEditing && id) {
      getOneShare();
      setEdit(true);
    }
  }, [propsEditing]);

  return (
    <CenterLayout title="Events" className={false}>
      {edit ? (
        <EventsForm
          onClick={() => setEdit(false)}
          eventProps={propsEditing ? event : null}
        />
      ) : (
        <React.Fragment>
          <div>
            <LittleCard
              buttonText="Créer en event"
              icon="icon-write-bubble"
              onClick={() => setEdit(true)}
              style={{ marginTop: "-10px" }}
            />
          </div>
          <EventPage />
        </React.Fragment>
      )}
    </CenterLayout>
  );
}
