import React, { useCallback, useEffect, useState } from "react";
import L from "leaflet";
import "./showElement.css";
import useSupercluster from "use-supercluster";
import { Marker, useMap } from "react-leaflet";

const icons = {};
const fetchIcon = (count, size) => {
  if (!icons[count]) {
    icons[count] = L.divIcon({
      html: `<div class="cluster-marker" style="width: ${size}px; height: ${size}px;">
        ${count}
      </div>`,
    });
  }
  return icons[count];
};

const markerIcon = (img) =>
  new L.Icon({
    iconUrl: img || "/assets/icons/Map/ClusterMarker.svg",
    iconSize: [30, 30],
    className: "icon-map-unique-ping",
  });

function ShowElement({ data, callBack = () => {} }) {
  const maxZoom = 22;
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(12);
  const map = useMap();

  // get map bounds
  function updateMap() {
    const b = map.getBounds();
    setBounds([
      b.getSouthWest().lng,
      b.getSouthWest().lat,
      b.getNorthEast().lng,
      b.getNorthEast().lat,
    ]);
    setZoom(map.getZoom());
    if (map.getZoom() < 13) {
      callBack("close");
    }
  }

  const onMove = useCallback(() => {
    updateMap();
  }, [map]);

  React.useEffect(() => {
    console.log(data);
    if (data && data.length == 1) {
      map.setView([data[0].latitude, data[0].longitude], 6, {
        animate: true,
        duration: 1,
      });
    }
    //if there is multiple data, we set the map view zoom and center to the middle of all the coordinates
    if (data && data.length > 1) {
      const lat =
        data.reduce((acc, curr) => acc + curr.latitude, 0) / data.length;
      const lng =
        data.reduce((acc, curr) => acc + curr.longitude, 0) / data.length;
      map.setView([lat, lng], 3, {
        animate: true,
        duration: 1,
      });
    }
  }, [data]);

  React.useEffect(() => {
    updateMap();
  }, [map]);

  useEffect(() => {
    map.on("move", onMove);
    return () => {
      map.off("move", onMove);
    };
  }, [map, onMove]);
  const points = data.map((element) => ({
    type: "Feature",
    properties: {
      cluster: false,
      id: element.id,
      category: element.type,
      ...element.User,
    },
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(element.longitude),
        parseFloat(element.latitude),
      ],
    },
  }));

  const { clusters, supercluster } = useSupercluster({
    points: points,
    bounds: bounds,
    zoom: zoom,
    options: { radius: 75, maxZoom: 20, minZoom: 2 },
  });

  return (
    <>
      {clusters.map((cluster) => {
        // every cluster point has coordinates
        const [longitude, latitude] = cluster.geometry.coordinates;
        // the point may be either a cluster or a crime point
        const { cluster: isCluster, point_count: pointCount } =
          cluster.properties;

        // we have a cluster to render
        if (isCluster) {
          return (
            <Marker
              key={`cluster-${cluster.id}`}
              position={[latitude, longitude]}
              icon={fetchIcon(pointCount, 60)}
              eventHandlers={{
                click: () => {
                  const expansionZoom = Math.min(
                    supercluster.getClusterExpansionZoom(cluster.id),
                    maxZoom
                  );
                  map.setView(
                    [latitude - 0.001, longitude + 0.02],
                    expansionZoom,
                    {
                      animate: true,
                    }
                  );
                  callBack(
                    supercluster.getChildren(cluster.properties.cluster_id)
                  );
                },
              }}
            />
          );
        }

        // we have a single point (crime) to render
        return (
          <Marker
            key={`element-${cluster.properties.id}`}
            position={[latitude, longitude]}
            icon={markerIcon(cluster.properties.avatarPath)}
            eventHandlers={{
              click: () => {
                map.setView([latitude - 0.001, longitude + 0.02], 22, {
                  animate: true,
                });
                callBack([cluster]);
              },
            }}
          />
        );
      })}
    </>
  );
}

export default ShowElement;
