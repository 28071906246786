import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card } from "components/molecules/AdminPanel";
import { fetchAllBranches } from "api/Branches";
import CropImage from "components/molecules/CropImage";
import ErrorFields from "components/molecules/ErrorsMessages/errorForm";
import { editPhotos, editPhotosFile, sendPhoto } from "api/Phototheque";

export default function FormPhototheque({
  photo = null,
  onCancel = () => {},
  onSubmit = () => {},
}) {
  const [branches, setBranches] = useState([]);
  const [file, setFile] = useState(null);
  const [fileBlob, setFileBlob] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    setError(null);
    const { firstname, lastname, customId, branchId } = e.target;
    if (
      !firstname.value ||
      !lastname.value ||
      !branchId.value ||
      (!file && !photo?.avatar)
    ) {
      setLoading(false);
      setError("Veuillez remplir tous les champs obligatoires");
      return;
    }
    try {
      const response = photo ? await handleUpdate(e) : await handleCreate(e);
      onSubmit({ type: photo ? "update" : "create", response });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.response?.data?.error?.message || error.message);
      return;
    }
  };

  const handleCreate = async (e) => {
    const formData = new FormData();
    formData.append("avatar", file);
    formData.append("firstname", e.target.firstname.value);
    formData.append("lastname", e.target.lastname.value);
    formData.append("customId", e.target.customId.value);
    formData.append("branchId", e.target.branchId.value);
    try {
      const response = await sendPhoto(formData);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const handleUpdate = async (e) => {
    try {
      const response = await editPhotos(photo.id, {
        firstname: e.target.firstname.value,
        lastname: e.target.lastname.value,
        customId: e.target.customId.value,
        branchId: e.target.branchId.value,
      });

      if (file) {
        const formData = new FormData();
        formData.append("avatar", file);
        const fileUploaded = await editPhotosFile(photo.id, formData);
        if (fileUploaded) {
          response.avatar = fileUploaded.avatar;
        }
      }
      return response;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    const fetchBranches = async () => {
      const branches = await fetchAllBranches();
      setBranches(branches);
    };
    fetchBranches();
  }, []);

  // if (!branches.length) return <div></div>;

  return (
    <div className="Aform-body-admin">
      <Card>
        <span className="back" onClick={onCancel}>
          <i className="icon-short-arrow" style={{ fontSize: "9px" }} /> Retour
        </span>
        <form onSubmit={handleSubmit}>
          <div className="admin-form">
            <div className="phototheque-form__image">
              <label
                className="phototheque-form__image__upload"
                style={{
                  backgroundImage: `linear-gradient(0deg, #161b4b2b 0%, #161b4b2b 100%), url("${
                    fileBlob
                      ? fileBlob
                      : process.env.REACT_APP_API_URL + photo?.avatar
                  }")`,
                }}
                htmlFor="upload-file"
              >
                <div className="">
                  {!fileBlob ? (
                    <h4>
                      Déposer
                      <br />
                      une image
                    </h4>
                  ) : (
                    <h4>
                      Remplacer
                      <br />
                      l'image
                    </h4>
                  )}
                  <i className="icon-cloud" style={{ fontSize: "80px" }} />
                  <p>jusqu'à 5MO</p>
                </div>
              </label>
              <input
                id="upload-file"
                type="file"
                onChange={(e) => {
                  if (e.target.files[0]) setFile(e.target.files[0]);
                }}
                accept="image/*"
                name="file"
              />
              {file ? (
                <CropImage
                  setImage={setFile}
                  setImageBlob={setFileBlob}
                  src={file}
                  ratio={4 / 4}
                />
              ) : (
                ""
              )}
              <div className="phototheque-form__inputs">
                <input
                  type="text"
                  placeholder="Nom*"
                  name="lastname"
                  defaultValue={photo?.lastname}
                />
                <input
                  type="text"
                  placeholder="Prénom*"
                  name="firstname"
                  defaultValue={photo?.firstname}
                />
                <input
                  type="text"
                  placeholder="ID"
                  name="customId"
                  defaultValue={photo?.customId}
                />
                <select name="branchId" id="job" defaultValue={photo?.branchId}>
                  <option
                    value=""
                    disabled
                    {...(photo?.branchId ? {} : { selected: true })}
                  >
                    Métier
                  </option>
                  {branches.map((branch) => (
                    <option
                      value={branch.id}
                      key={branch.id}
                      {...(photo?.branchId === branch.id
                        ? { selected: true }
                        : {})}
                    >
                      {branch.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {error && <ErrorFields message={error} />}
          <div className="d-flex justify-content-center phototheque-form__button">
            <button className="btn bg-main" type="submit">
              Ajouter
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
}

FormPhototheque.propTypes = {
  photo: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
