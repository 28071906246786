import React, { useContext, useState, useEffect } from "react";
import { detectUrl } from "../../helpers/DetectUrl";

import { fetchMessageById } from "../../api/Messages";
import SocketContext from "../../contexts/socket";

//:: CSS & Component import
import "./MessagesFeed.css";
import Comment from "./Comment/Comment";
import OptionsIcon from "components/atoms/icons/options";

import { TrashIcon } from "components/atoms/icons/trash";
import { PencilIcon } from "components/atoms/icons/pencil";

import DefaultModal from "components/molecules/Modals/Default";
import { Link } from "react-router-dom";

import moment from "moment";

import MentionsForm from "./MentionsForm";
import FormatChatMessage from "helpers/FormatChatMessage";
import Attachment from "./Attachment";

export default function Message({
  key,
  messageProps,
  avatarPath,
  userId,
  isLiked,
  messageLiked,
  onDeleteMessage,
  users = [],
}) {
  const [isCurrentLiked, setIsCurrentLiked] = useState(false);
  const [messageContent, setMessageContent] = useState();
  const [message, setMessage] = useState(messageProps);
  const [isOnUpdate, setOnUpdate] = useState(false);
  const [contentToUpdate, setContentToUpdate] = useState(message.content);
  const [isComment, setIsComment] = useState(false);
  const [like, setLike] = useState(null);
  const [likesModal, setLikesModal] = useState(false);
  const [confimDeleteModal, setConfirmDeleteModal] = useState(false);

  const { socket } = useContext(SocketContext);
  const { id, content, createdAt, author, Comments, MessageLike, Attachments } =
    message;

  const updateLike = () => {
    socket.emit("feed:message:like", { messageId: id }, (err, data) => {
      if (err) {
        console.log(err);
        return;
      }
      setLike(data.likes);
    });
  };

  const handleContentUpdating = (e) => {
    setContentToUpdate(e.target.value);
  };

  const handleLikeClick = (e) => {
    e.preventDefault();
    setIsCurrentLiked(!isCurrentLiked);
    updateLike();
  };

  const fetchMessage = async () => {
    const newContent = await fetchMessageById(id);
    setMessage(newContent);
  };

  const updateMessage = async () => {
    if (contentToUpdate?.length < 1) {
      return;
    }
    setMessageContent(contentToUpdate);
    socket.emit(
      "feed:update:message",
      { messageId: id, content: contentToUpdate, userId: userId },
      (err) => {
        if (err) {
          console.log(err);
          return;
        }
      }
    );
  };

  const deleteMessage = async () => {
    await onDeleteMessage(id);
  };

  useEffect(() => {
    setLike(MessageLike?.length);
  }, [MessageLike]);

  useEffect(() => {
    setIsCurrentLiked(isLiked);
  }, [isLiked]);

  useEffect(() => {
    // ~ If an url is detected, we replace it by a link
    let msg = content;
    setMessageContent(msg);

    // ~ Catch like event
    socket.on("feed:message:like:update", (data) => {
      if (data.messageId == id) {
        setLike(data.likes);
      }
    });

    socket.on("feed:comment:new", (data) => {
      console.log("fetching message", data, id);
      if (data.messageId == id || data.parent == id) {
        fetchMessage();
      }
    });

    return () => {
      socket.off("feed:message:like:update");
      socket.off("feed:comment:new");
    };
  }, []);

  const handleCommentClick = (e) => {
    e.preventDefault();
    setIsComment(!isComment);

    if (isComment) return;

    setTimeout(async () => {
      const inputMessage = document.getElementById(
        `comment-form-${message.id}`
      );
      inputMessage.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
      inputMessage.focus({ preventScroll: true });
    }, 100);
  };

  return (
    <div key={key} className="message-container" id={"messageId-" + id}>
      {userId === author.id && (
        <div className="message-options">
          <button>
            <OptionsIcon />
          </button>
          {isOnUpdate ? (
            <ul>
              <li onClick={() => setOnUpdate(false)}>Annuler</li>
            </ul>
          ) : (
            <ul>
              <li onClick={() => setOnUpdate(true)}>
                <PencilIcon /> Modifier
              </li>
              <li onClick={() => setConfirmDeleteModal(true)}>
                <TrashIcon />
                Supprimer
              </li>
            </ul>
          )}
        </div>
      )}
      <div className="message-header-box">
        <div
          id="user-pic-msg"
          className="message-img"
          style={{
            backgroundImage: `url(${author && author.avatarPath
              ? author.avatarPath
              : "/assets/icons/user-solid.svg"
              })`,
          }}
        />
        <div>
          <Link to={`/users/${author.id}`}>
            <h2 className="message-owner">
              {author ? author.firstname + " " + author.lastname : "username"}
            </h2>
          </Link>
          <p className="message-time">
            {createdAt ? moment(createdAt).fromNow() : "-----"}
          </p>
        </div>
      </div>
      <div className="message-text">
        {isOnUpdate ? (
          <div className="message-update-form">
            {/* <textarea
              value={contentToUpdate}
              onChange={(e) => handleContentUpdating(e)}
            /> */}
            <MentionsForm
              users={users}
              onChange={(e) => handleContentUpdating(e)}
              value={contentToUpdate}
              className="message-update-form-textarea"
              placeholder="Commencer une discussion..."
            />

            <div>
              <button onClick={() => setOnUpdate(false)} className="cancel">
                Annuler
              </button>
              <button
                onClick={() => {
                  updateMessage();
                  setOnUpdate(false);
                }}
              >
                Enregistrer
              </button>
            </div>
          </div>
        ) : (
          <>
            <FormatChatMessage text={messageContent} />
            {Attachments?.map(({ blobPath }, index) => (
              <Attachment key={index} filePath={blobPath} />
            ))}
          </>
        )}
      </div>
      <div className="message-footer-box">
        <div className={`message-footer-component`}>
          <div className="like-counter" onClick={() => setLikesModal(true)}>
            <img src="/assets/icons/pouce_off.svg" alt="" />
          </div>
          <a className="like-a" onClick={() => setLikesModal(true)}>
            {like}
          </a>
          <ul className="popup-users-liked">
            {MessageLike?.map((like, index) => {
              if (index > 4) return null;
              return (
                <li key={like.id}>
                  <a href={`/users/${like.User.id}`}>
                    {like.User.firstname} {like.User.lastname}
                  </a>
                </li>
              );
            })}
            {MessageLike?.length > 5 && (
              <li>
                <a href="#" onClick={() => setLikesModal(true)}>
                  Et {MessageLike.length - 5} autres...
                </a>
              </li>
            )}
          </ul>
        </div>

        <p>
          {message?.Comments?.length}{" "}
          {message?.Comments?.length < 2 ? "commentaire" : "commentaires"}
        </p>
      </div>
      <div className="interaction-container">
        <span onClick={(e) => handleLikeClick(e)}>
          <img
            src={
              isCurrentLiked
                ? "/assets/icons/pouce_on.svg"
                : "/assets/icons/pouce_off.svg"
            }
            style={{ cursor: "pointer", height: "18px" }}
            alt=""
          />
          J'aime
        </span>
        <span onClick={handleCommentClick}>
          <img
            src="/assets/icons/Message/comment.svg"
            style={{ cursor: "pointer", height: "18px" }}
            alt=""
          />
          Commenter
        </span>
      </div>
      <Comment
        comment={{
          commentsArray: Comments,
          messageId: message.id,
        }}
        userId={userId}
        avatarPath={avatarPath}
        isComment={isComment}
        messageLiked={messageLiked}
        users={users}
      />
      <DefaultModal
        isOpen={likesModal}
        setIsOpen={setLikesModal}
        className="likes-list-modal"
        title="Publication aimée par :"
      >
        <div>
          <ul className="">
            {MessageLike?.map((like) => (
              <li key={like.id}>
                <img
                  src={like.User.avatarPath || "/assets/icons/user-solid.svg"}
                  alt="avatar"
                />
                <a href={`/users/${like.User.id}`}>
                  {like.User.firstname} {like.User.lastname}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </DefaultModal>
      <DefaultModal
        isOpen={confimDeleteModal}
        setIsOpen={setConfirmDeleteModal}
        title="Supprimer la publication ?"
      >
        <p className="text-center">
          Êtes-vous sûr de vouloir supprimer cette publication ? Cette action
          est irréversible.
        </p>
        <div className="d-flex gap-10 mt-20 justify-content-center">
          <button
            className="btn text-main"
            onClick={() => setConfirmDeleteModal(false)}
          >
            Annuler
          </button>
          <button className="btn bg-main" onClick={() => deleteMessage()}>
            Supprimer
          </button>
        </div>
      </DefaultModal>
    </div>
  );
}
