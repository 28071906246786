import { useState } from "react";
import { MentionsInput, Mention } from "react-mentions";
import MentionsForm from "../MentionsForm";

//:: CSS import
import "./Comment.css";

export default function CommentForm({
  sender,
  isComment,
  comment,
  avatarPath,
  users = [],
}) {
  const [messageContent, setMessageContent] = useState("");
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);

  const { sendFct, messageId } = sender;
  function handleChange(event) {
    const { value } = event.target;
    setMessageContent(value);
  }

  function handleSelectFile(event) {
    const { files } = event.target;
    if (files.length > 0) {
      setFile(files[0]);
      // if is a image
      if (files[0].type.includes("image")) {
        setFileName({
          preview: URL.createObjectURL(files[0]),
          name: files[0].name,
        });
      } else {
        // size in MB or KB
        setFileName({
          name: files[0].name,
          size:
            files[0].size > 1000000
              ? (Math.round(files[0].size / 1000000)) + " MB"
              : (Math.round(files[0].size / 1000)) + " KB",
        });
      }
    }
  }

  return (
    <div
      className={
        isComment ? "comm-comment-form-container" : "comment-form-container"
      }
    >
      <div className={isComment ? "comm-input-box" : "nocomm-input-box"}>
        <img
          src={avatarPath ? avatarPath : "/assets/icons/user-solid.svg"}
          alt="avatar"
        />

        <MentionsForm
          className={isComment ? "comm-comment-input" : "comment-input"}
          placeholder={isComment ? "Répondre..." : "Ecrivez un commentaire..."}
          id={`comment-form-${messageId}`}
          onChange={(e) => handleChange(e)}
          value={messageContent}
          users={users}
        />
        {messageContent && (
          <div className="com-button-box">
            <button
              className="comm-button-comment"
              onClick={() => {
                if (messageContent) {
                  sendFct(messageContent, file, messageId);
                  setMessageContent("");
                }
              }}
            >
              <i className="icon-send" />
              Envoyer
            </button>
          </div>
        )}
      </div>
      {!fileName ? (
        <button htmlFor="upload-file-com" className="comm-button-attachment">
          <input
            type="file"
            id="upload-file-com"
            name="upload-file-com"
            style={{
              top: 0,
              left: 0,
              cursor: "pointer",
              height: "100%",
              width: "100%",
              position: "absolute",
              opacity: 0,
              zIndex: 0,
            }}
            onChange={handleSelectFile}
          />
          <p>
            <i class="icon-attachment-clip"></i>
            <span>{fileName ? fileName.name : "Ajouter une pièce jointe"}</span>
          </p>
        </button>
      ) : (
        <div className="comm-file-preview">
          <button
            onClick={() => {
              setFile(null);
              setFileName(null);
            }}
          >
            {/* <i class="icon-trash" style={{ color: "red" }}></i> */}
            <i className="icon-close"
              style={!fileName.preview ? {
                fontSize: 10,
              } : {}}
            ></i>
          </button>
          {fileName.preview ? (
            <img src={fileName.preview} alt="preview" />
          ) : (
            <div>
              <p>{fileName.name}</p>
              <p>{fileName.size}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
