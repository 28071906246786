import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import Item from "./Item";
import "./topbar.css";
import { TopBarItems } from "../../utils/topbar.json";
import { fetchAllNotifications } from "../../api/Notifications/Notifications";
import { fetchAccount } from "../../api/Users/Me";
import MobileNavbar from "./MobileNavbar";
import { EditMode } from "../../contexts/editmode";
import ProposeContent from "../ProposeContent";
import useAnalytics from "hooks/useAnalytics";

const TopBar = () => {
  let history = useHistory();
  const [isFocused, setIsFocused] = useState(false);
  const [notif, setNotif] = useState([]);
  const [accountInfo, setAccountInfo] = useState([]);
  const { editing, setEditing } = useContext(EditMode);

  async function getNotifications() {
    setNotif(await fetchAllNotifications());
  }
  let location = useLocation();

  async function getAccount() {
    setAccountInfo(await fetchAccount());
  }

  useEffect(() => {
    getAccount();
  }, []);

  useEffect(() => {
    getNotifications();
    if (location.state && location.state.proposeContentModal) return;
    window.scrollTo(0, 0);
    // if (localStorage.getItem("cookiegdpr")) {
    //   reactGAInit();
    // }
  }, [location]);

  useAnalytics();

  const convertURLForRedirect = () => {
    let formatedLocation = "";
    if (location.pathname.includes("/admin") && editing) {
      formatedLocation = location.pathname.replace("/admin", "");
    } else if (!location.pathname.includes("/admin") && !editing) {
      formatedLocation = "/admin" + location.pathname;
    } else {
      formatedLocation = location.pathname;
    }
    return formatedLocation;
  };

  return (
    <Fragment>
      <div
        className={`${editing ? "admin-mode" : ""} ${
          isFocused ? "topbar__burger_icon active" : "topbar__burger_icon"
        }`}
        onClick={() => setIsFocused(!isFocused)}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>

      <div
        className={
          editing ? "topbar__container admin-mode" : "topbar__container"
        }
      >
        <Link to="/">
          <img
            src={
              editing ? "/assets/img/logo-white.svg" : "/assets/img/logo.svg"
            }
            alt="logo"
            className="topbar__logo"
          />
        </Link>
        {editing ? (
          <div className="current-mode__isAdmin">
            <p>MODE EDITION</p>
          </div>
        ) : (
          ""
        )}
        <div className="topbar__items">
          {editing ? (
            <button
              className="btn btn-small btn-fill current-mode__isAdmin"
              onClick={(e) => {
                setEditing(!editing);
                history.push(convertURLForRedirect());
              }}
            >
              Passer en mode utilisateur
            </button>
          ) : (
            ""
          )}
          <p className="topbar__profil-name">
            {accountInfo.firstname
              ? accountInfo.firstname + " " + accountInfo.lastname
              : "Chargement..."}
          </p>
          <Item data={TopBarItems[0]} />
          <Item data={TopBarItems[1]} notif={notif} setNotif={setNotif} />
          {/* <Item data={TopBarItems[2]} /> */}
        </div>
      </div>

      <MobileNavbar isOpen={isFocused} setIsOpen={setIsFocused} />
      <ProposeContent />
    </Fragment>
  );
};
export default TopBar;
