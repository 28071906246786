import axios from "axios";
import getFormatedToken from "../helpers/getFormatedToken";

export async function sendLogin(email, password) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/authentication/login`,
      {
        email,
        password,
      },
      { validateStatus: () => true }
    );
    return response.data;
  } catch (error) {
    // TODO: Handle error when calling sendLogin, avoid console.log in production
    // console.log(error);
  }
}

export async function sendCode(email, code, token) {
  try {
    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/authentication/login/verify`,
      data: {
        email,
        code,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    // TODO: Handle error properly
    // console.log(error);
  }
}

export async function checkMe(token) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/users/me`,
      {
        headers: { Authorization: `Bearer ${token}` },
        validateStatus: () => true,
      }
    );
    return response.data.data.user;
  } catch (error) {
    // TODO: Handle error properly
    // console.log(error);
    return null;
  }
}
