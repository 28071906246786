import React, { useState, useEffect } from "react";

import CenterLayout from "../../layouts/CenterLayout";
import LinksContent from "../../components/Links/Links";
import Search from "../../components/Search/SearchFilter";
import FilterForm from "../../components/Filter/FilterForm";

import { deleteLinks } from "../../api/Links";
import { fetchAllLinks } from "../../api/Links";

export default function LinksPage() {
  const [links, setLinks] = useState([]);
  const [isCollaped, setIsCollaped] = useState(false);
  const [filters, setFilters] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");

  async function getAllLinks(filters) {
    setLinks(await fetchAllLinks(filters));
  }

  function deleteOneLink(id) {
    setLinks((links) => {
      return links.filter((link) => link.id !== id);
    });

    deleteLinks(id);
  }

  useEffect(() => {
    getAllLinks(filters);
  }, [filters]);

  const filteredLinks = links.filter(({ title }) =>
    title.toLowerCase().includes(searchFilter)
  );

  return (
    <React.Fragment>
      <div className="top__content">
        <FilterForm
          filtersCallback={setFilters}
          category={false}
          btnOpen={true}
          publishedDate={false}
        />

        <Search
          onChange={(filter) => setSearchFilter(filter.toLowerCase())}
          className={"search__reduced"}
        />
      </div>
      <LinksContent items={filteredLinks} deleteOneLink={deleteOneLink} />
    </React.Fragment>
  );
}
