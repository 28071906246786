import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./subtheme.css";

// components
import Header from "../../components/Thematics/Header/";
import Content from "../../components/Thematics/Content/";
import Informations from "../../components/Thematics/Informations/";
import Files from "../../components/Thematics/Files/";

// molecules
import LinksByThematic from "../../components/molecules/Links";

// api
import { fetchAllThemes } from "../../api/Thematics/Themes";

// molecules
import { ThreeNumbers } from "../../components/molecules/ThreeNumbers";

export default function Thematics() {
  const [themesInfo, setThemesInfo] = useState([]);
  const [subthemesOfMainthemeID, setSubthemesOfMainthemeID] = useState([]);
  const [themeInfo, setThemeInfo] = useState({});
  const { theme } = useParams();

  async function getAllThemes() {
    setThemesInfo(await fetchAllThemes());
  }

  useEffect(() => {
    getAllThemes();
  }, [theme]);

  useEffect(() => {
    const themeParsed = theme.replace(/_/g, " ");
    let themeWhereName = {};

    if (themesInfo.length) {
      themeWhereName = themesInfo.filter(
        (item) =>
          themeParsed ===
          item.name
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
      );
      if (themeWhereName.length === 0) {
        return window.alert("page introuvable");
      }
      let temparray = [];
      for (const key in themeWhereName[0].SubThemes) {
        temparray.push(themeWhereName[0].SubThemes[key].id);
      }
      setSubthemesOfMainthemeID(temparray);
      setThemeInfo(...themeWhereName);
    }
  }, [themesInfo]);

  const { id, color, n1, n2, n3, Question, SubThemes } = themeInfo;
  return (
    <React.Fragment>
      <Header data={themeInfo} />
      <ThreeNumbers color={color} numbers={[n1, n2, n3]} />
      <Content content={SubThemes} html={themeInfo.Content} color={color} />
      <Informations
        thematic_id={id}
        subthemes_of_maintheme={subthemesOfMainthemeID}
        questions={Question}
        color={color}
      />
      <Files
        color={color}
        thematic_id={id}
        subthemes_of_maintheme={subthemesOfMainthemeID}
      />
      <LinksByThematic color={color} thematic_id={id} />
    </React.Fragment>
  );
}
