import React, { useState, useContext } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import PermissionContext from "../../contexts/permission";

import { EditMode } from "../../contexts/editmode";

const Item = ({ item, deleteWelcome }) => {
  const { id, job, expertise, fullName, dateIn, avatarUrl, Branch } = item;
  const { editing } = useContext(EditMode);
  const { Role } = useContext(PermissionContext);

  return (
    <div className="welcome__item">
      <div>
        <div className="welcome__first_container">
          <p className="welcome__p1 mb-5">
            <Moment format="DD/MM/YYYY">{dateIn}</Moment>
          </p>
        </div>

        <div className="welcome__second_container">
          <img
            className="welcome__image"
            src={
              avatarUrl
                ? avatarUrl
                : "/assets/img/avatar.svg"
            }
            alt={fullName}
          />
          <div className="welcome__third_container">
            <p>{fullName}</p>
            <p className="welcome__p1">{job}</p>
            <p className="welcome__p3">{expertise}</p>
            <p className="welcome__p3">{Branch?.name}</p>
          </div>
          {Role.length > 0 &&
            Role.some(({ name }) => name === "ADMIN") &&
            editing ? (
            <div className="icon-admin-welcome-box">
              <Link to={"/admin/welcome/" + id}>
                <i className="icon-write-bubble" />
              </Link>
              <i
                className="icon-trash trash-welcome"
                onClick={() => {
                  if (
                    window.confirm(
                      `Êtes vous sûr de vouloir suprimer le newcomer :  ${fullName} ?`
                    )
                  ) {
                    deleteWelcome(id);
                  }
                }}
              />
            </div>
          ) : (
            <React.Fragment />
          )}
        </div>
      </div>
    </div>
  );
};

export default Item;
