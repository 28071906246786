import React, { useState, useEffect } from "react";
import "./style.css";

// api
import { fetchAllFiles } from "../../../api/Files";

//components
import NotFound from "../../molecules/ErrorsMessages/NotFound";
import Item from "../../ContentListShares/Item";
import HRTrendsList from "../../../components/molecules/HRTrends/HRTrends";
import SliderCollum from "../../molecules/Slider/SliderCollum";
import { SwiperSlide } from "swiper/react";
import ItemListShares from "../../ContentListShares/Item";
import Search from "../../Search/SearchFilter";
import CTAnotFound from "../../molecules/ErrorsMessages/CTAnotFound";

export default function Files({
  color,
  thematic_id = null,
  sub_thematic_id = null,
  filter,
  subthemes_of_maintheme,
}) {
  const [library, setLibrary] = useState([]);
  const [libraryFiltered, setLibraryFiltered] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");
  async function getAllLibraryWhereThematic() {
    if (thematic_id) {
      setLibrary(
        await fetchAllFiles([
          `main_theme:${thematic_id}`,
          "isOfficial:Library:true,HRTrends:false",
        ])
      );
    } else if (sub_thematic_id) {
      setLibrary(
        await fetchAllFiles([
          `sub_theme:${sub_thematic_id}`,
          "isOfficial:Library:true,HRTrends:false",
        ])
      );
    }
  }

  function searchFileInArray(search) {
    if (search) {
      setLibraryFiltered(
        library.filter(
          (file) =>
            file.title.toLowerCase().includes(search.toLowerCase()) ||
            (file.hashtags.length &&
              file.hashtags[0].includes(search.toLowerCase()))
        )
      );
    } else {
      setLibraryFiltered(library);
    }
  }
  useEffect(() => {
    getAllLibraryWhereThematic();
  }, [thematic_id, sub_thematic_id]);
  useEffect(() => {
    searchFileInArray();
  }, [library]);

  return (
    <div className="themactics__files">
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h2 className={"subtheme__subtitle text-" + color}>Library</h2>

          {library && library.length ? (
            <Search
              onChange={(text) => searchFileInArray(text)}
              className={"search__reduced"}
            />
          ) : (
            ""
          )}
        </div>
        {library.length > 0 ? (
          libraryFiltered.length > 4 ? (
            <SliderCollum
              className="slider__shares_home slider__shares_tow-row"
              column={2}
            >
              {libraryFiltered
                .filter((file) => file.isOfficial === true)
                .sort(function (a, b) {
                  return new Date(b.createdAt) - new Date(a.createdAt);
                })
                .map((item, index) => (
                  <SwiperSlide key={index}>
                    <ItemListShares item={item} />
                  </SwiperSlide>
                ))}
            </SliderCollum>
          ) : libraryFiltered.length > 0 ? (
            <div className="themactics__files-library">
              {libraryFiltered
                .sort(function (a, b) {
                  return new Date(b.createdAt) - new Date(a.createdAt);
                })
                .map((item, index) => (
                  <Item item={item} key={index} />
                ))}
            </div>
          ) : (
            <NotFound element="fichier" />
          )
        ) : (
          <CTAnotFound
            color={color}
            thematic_id={thematic_id}
            contentType={1}
          />
        )}
      </div>
      <div className="themactics__files-hrtrends">
        <h2 className={"subtheme__subtitle text-" + color}>HR Trends</h2>
        <div>
          <HRTrendsList
            title={false}
            filter={filter}
            color={color}
            subthemes_of_maintheme={subthemes_of_maintheme}
            thematic={{
              color: color,
              id: thematic_id,
            }}
          />
        </div>
      </div>
    </div>
  );
}
