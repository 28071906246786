import React, { useEffect, useState } from "react";

import FilterForm from "../../../components/Filter/FilterForm";
import { Card } from "../../../components/molecules/AdminPanel/index";
import CKEditor from "../../../components/molecules/CKEditor/CKEditorBasic";

import ErrorDiv from "../../../components/molecules/ErrorsMessages/errorForm";
import { updateOneNews, createNewNews } from "../../../api/News/News";
import { FiltersContext } from "../../../contexts/filters";

import "./NewsForm.css";
import { Link, useParams, useHistory } from "react-router-dom";
import LoadingBar from "../../../components/molecules/Loaders/Bar";
import CropImage from "../../../components/molecules/CropImage";

export default function NewsForm({ onClick, newsProps }) {
  const [title, setTitle] = useState(null);
  const [author, setAuthor] = useState(null);
  const [file, setFile] = useState(null);
  const [fileBlob, setFileBlob] = useState(null);
  const [banner, setBanner] = useState(null);
  const [bannerBlob, setBannerBlob] = useState(null);
  const [next, setNext] = useState(false);
  const [filter, setFilter] = useState([]);
  const [content, setContent] = useState();
  const [errorField, setErrField] = useState(false);
  const [errorFieldCK, setErrFieldCK] = useState(false);
  const [sending, setSending] = useState(false);

  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    const zob = document.querySelector(".reset_filter_title");
    const zob2 = document.querySelector(".calendar_selection");
    if (zob) {
      zob.style = "display: none";
    }

    if (zob2) {
      zob2.style = "display: none";
    }

    if (newsProps) {
      setTitle(newsProps.title);
      setAuthor(newsProps.author);

      if (newsProps.NewsContent[0] && newsProps.NewsContent[0].Content) {
        setContent(newsProps.NewsContent[0].Content.html);
      }
    }
  }, [newsProps]);

  const conversionDuTableauAvantLenvoie = () => {
    let mainThemeId = [];
    let subThemeId = [];
    let branchId = [];

    filter.forEach((row, index) => {
      if (row.includes("main_theme")) {
        mainThemeId.push(Number(row.split(":")[1]));
      } else if (row.includes("sub_theme")) {
        subThemeId.push(Number(row.split(":")[1]));
      } else if (row.includes("branch")) {
        branchId.push(Number(row.split(":")[1]));
      }
    });
    return { mainThemeId, subThemeId, branchId };
  };

  const updateNews = async (e, state = true) => {
    e.preventDefault();
    let published = state;
    // ~ Build MainThemeId, SubThemeId, BranchId Array with Filters

    if (!title) {
      setErrField(true);
      setNext(false);
      return;
    }
    if (!author) {
      setErrField(true);
      setNext(false);
      return;
    }
    if (!content || content == "<i>Commencer l'édition...</i>") {
      setErrFieldCK(true);
      return;
    }

    let { mainThemeId, subThemeId, branchId } =
      conversionDuTableauAvantLenvoie();

    if (id) {
      const res = await updateOneNews(
        id,
        title,
        author,
        file,
        banner,
        content,
        mainThemeId,
        subThemeId,
        branchId,
        published
      );
      if (!res) {
        history.push("/admin/news");
        return;
      }
      setErrField(res);
    } else {
      const res = await createNewNews(
        title,
        author,
        file,
        banner,
        content,
        mainThemeId,
        subThemeId,
        branchId,
        published
      );
      if (!res) {
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
      setErrField(res);
    }
  };

  if (!next) {
    return (
      <div className="Aform-body-admin">
        <Card>
          <Link to="/admin/news">
            <a className="back" onClick={onClick}>
              <i className="icon-short-arrow" style={{ fontSize: "9px" }} />
              Retour
            </a>
          </Link>
          <h1 className="title">Étape 1/2</h1>
          <form className="admin-form">
            <div className="admin-input-box">
              <input
                className="admin-input"
                type="text"
                placeholder="Titre de l'article*"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                required
              />
              {errorField && <ErrorDiv message="Veuillez ajouter un titre" />}
              <input
                className="admin-input"
                type="text"
                placeholder="auteur de l'article*"
                onChange={(e) => setAuthor(e.target.value)}
                value={author}
                required
              />
              {errorField && <ErrorDiv message="Veuillez ajouter un auteur" />}
            </div>
            <div>
              {/* {id && <img src={file} className="img-modif-admin-form" />} */}
              <label
                className="upload-style"
                htmlFor="upload-file"
                style={{
                  backgroundImage: `linear-gradient(0deg, #161b4b2b 0%, #161b4b2b 100%), url("${
                    fileBlob ? fileBlob : newsProps ? newsProps.logoImgPath : ""
                  }")`,
                }}
              >
                <i className="icon-cloud" style={{ fontSize: "2rem" }} />
                <p className="btn btn-small bg-main">
                  {file || (newsProps && newsProps.logoImgPath)
                    ? "Changer la vignette"
                    : "Sélectionnez une vignette"}
                </p>
              </label>
              <input
                id="upload-file"
                type="file"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
                name="file"
              />
              {file ? (
                <CropImage
                  setImage={setFile}
                  setImageBlob={setFileBlob}
                  src={file}
                  ratio={4 / 3}
                />
              ) : (
                ""
              )}
              <br />
              <p className="text-center default-p">
                Recommandé: 500px x 250px (5MO max)
              </p>
            </div>
          </form>
          <div
            style={{
              borderBottom: "1px rgba(0, 0, 0, 0.15) solid",
              marginBottom: "30px",
            }}
          />
          <div className="admin-fitler-container">
            <FiltersContext.Provider value={newsProps}>
              <FilterForm
                filtersCallback={setFilter}
                category={false}
                // preChecked={newsProps}
                edition={true}
              />
            </FiltersContext.Provider>
            <button
              className="save-btn"
              onClick={(e) => {
                e.preventDefault();
                setNext(true);
              }}
            >
              Suivant
            </button>
          </div>
        </Card>
      </div>
    );
  } else {
    return (
      <div style={{ paddingTop: "70px", width: "100%" }}>
        <Card>
          <a
            className="back"
            onClick={() => {
              setNext(false);
            }}
          >
            <i className="icon-short-arrow" style={{ fontSize: "9px" }} />
            Retour
          </a>
          <h1 className="title" style={{ marginBottom: "30px" }}>
            Étape 2/2
          </h1>
          <div style={{ textAlign: "center" }}>
            <label
              className="upload-style"
              htmlFor="upload-file"
              style={{
                padding: "50px 0px",
                backgroundImage: `linear-gradient(0deg, #161b4b2b 0%, #161b4b2b 100%), url("${
                  bannerBlob
                    ? bannerBlob
                    : newsProps
                    ? newsProps.bannerImgPath
                    : ""
                }")`,
              }}
            >
              <i className="icon-cloud" style={{ fontSize: "2rem" }} />
              <p className="btn btn-small bg-main">
                {banner || (newsProps && newsProps.bannerImgPath)
                  ? "Changer la bannière"
                  : "Sélectionnez une bannière"}
              </p>
            </label>
            <input
              id="upload-file"
              type="file"
              onChange={(e) => {
                setBanner(e.target.files[0]);
              }}
              name="file"
            />
            {banner ? (
              <CropImage
                setImage={setBanner}
                setImageBlob={setBannerBlob}
                src={banner}
                ratio={16 / 9}
              />
            ) : (
              ""
            )}
            <br />
            <p className="text-center default-p">
              Recommandé: 1280px x 720px, 5MO (max)
            </p>
            <br />
          </div>
          <CKEditor
            placeholder={content}
            ckeditorCallback={setContent}
            updateState={(e) => {
              updateNews(e, false);
            }}
          />
          {errorFieldCK && <ErrorDiv message="Veuillez ajouter du contenu" />}
          <div className="btn-box-admin d-flex align-items-center gap-10">
            {sending && !errorField && !errorFieldCK && (
              <LoadingBar done={false} />
            )}
            {sending ? (
              <button
                className="save-btn"
                style={{ backgroundColor: "#66666b" }}
                disabled
              >
                Publier
              </button>
            ) : (
              <button
                className="save-btn"
                onClick={(e) => {
                  updateNews(e);
                  setSending(true);
                  setTimeout(() => {
                    setSending(false);
                    setErrField(false);
                    setErrFieldCK(false);
                  }, 10000);
                }}
              >
                Publier
              </button>
            )}
          </div>
        </Card>
      </div>
    );
  }
}
