import { useState } from "react";
import DropdownIcons from "../Thematics/DropdownIcons";
import { Link } from "react-router-dom";
import "./style.css";
import { fetchAllQuestions } from "../../../api/Questions/Questions";

export default function Item({ data: { entitled, MainThemes, answer } }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className={`questions__external-item ${isOpen ? "active" : ""}`}
      onClick={() => {
        setIsOpen(!isOpen);
      }}
    >
      <div>
        <p>{entitled}</p>
        {MainThemes ? <DropdownIcons thematics={MainThemes} /> : ""}
        <p className="questions__external-answer">{answer}</p>
      </div>
    </div>
  );
}
