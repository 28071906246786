import { useEffect, useState } from "react";
import fetchFileSize from "helpers/getFileSize";

export default function Attachment({ filePath = "", isInComment = false }) {
    const [fileSize, setFileSize] = useState("0 KB");
    const filename = filePath.split('/').pop()
    const extension = filename.split('.').pop();

    const getFileSize = async () => {
        const size = await fetchFileSize(filePath);
        // convert to KB or MB
        size > 1000000 ? setFileSize((Math.round(size / 1000000)) + " MB") : setFileSize(Math.round(size / 1000) + " KB");
    }

    useEffect(() => {
        getFileSize();
    }, []);


    if (extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'gif') {
        return (
            <img src={filePath} alt={filename} className="img-msg-chat" />
        )
    } else {
        return (
            <div className="message-attachment__container">
                <a className="message-attachment" href={filePath} style={isInComment ? { marginTop: 0 } : {}}>
                    <div className="message-attachment__icon">
                        <i className="icon-attachment-clip" />
                    </div>
                    <div className="message-attachment__name">
                        <p >{filename}</p>
                        <p>{fileSize}</p>
                    </div>
                </a>
            </div>
        )
    }
}