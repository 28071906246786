import React, { useState, useEffect } from "react";

import WelcomePage from "../../Welcome/WelcomePage";

import { fetchOneWelcome } from "../../../api/Newcomers";

import WelcomeForm from "./WelcomeForm";
import { LittleCard } from "../../../components/molecules/AdminPanel/index";
import CenterLayout from "../../../layouts/CenterLayout";
import { useParams } from "react-router";

export default function AWelcome({ propsEditing }) {
  const [edit, setEdit] = useState(false);
  const [welcome, setWelcome] = useState(false);
  const { id } = useParams();

  const getOneWelcome = async () => {
    setWelcome(await fetchOneWelcome(id));
  };

  useEffect(() => {
    if (propsEditing && id) {
      getOneWelcome();
      setEdit(true);
    }
  }, [propsEditing]);

  return (
    <CenterLayout title="Welcome">
      <LittleCard
        buttonText="Ajouter un collaborateur"
        icon="icon-write-bubble"
        onClick={() => setEdit(true)}
      />

      <WelcomePage />
      {edit ? (
        <div
          style={
            edit && {
              position: "fixed",
              backgroundColor: "#5c5c5c69",
              height: "100vh",
              width: "100vw",
              zIndex: "998",
              top: 0,
              left: 0,
            }
          }
        >
          <WelcomeForm
            onClick={() => setEdit(false)}
            welcomeProps={propsEditing ? welcome : null}
          />
          <div></div>
        </div>
      ) : (
        <React.Fragment />
      )}
    </CenterLayout>
  );
}
