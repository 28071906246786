import React, { useState, useEffect } from "react";
import ReactCodeInput from "react-code-input";
import Cookies from "universal-cookie";

//:: API imports
import { sendCode, sendLogin } from "../../api/Login";
import { ResetPassword } from "../../api/Users/ResetPassword";
import DefaultModal from "../../components/molecules/Modals/Default";

import "./Login.css";

export default function Login({ setter }) {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [sended, setSender] = useState({ sending: false, success: true });
  const [openModal, setOpenModal] = useState(false);
  const [step, setStep] = useState(1);
  const [code, setCode] = useState(null);
  const [user, setUser] = useState(null);
  const [resetPasswordInfo, setResetPasswordInfo] = useState({
    loading: false,
    success_message:
      "Si vous êtes inscrit sur le Portail RH, vous avez reçu un e-mail avec vos nouveaux identifiants.<br/> <br/> <p style='font-size: 16px;color: grey;'>Pour contacter un administrateur : <a style='font-size: 16px;color: grey;' href='mailto:HRTEAM@bouygues.com'>HRTEAM@bouygues.com</a></p>",
    isSent: false,
  });
  const cookies = new Cookies();

  const handleSenderRes = async (email, password) => {
    try {
      const res = await sendLogin(email, password);
      const { data } = res;

      setSender({
        ...sended,
        sending: false,
        success: true,
      });
      if (data.token) {
        setStep(2);
        setUser(data);
      }
    } catch (error) {
      // TODO: Handle error properly
      // console.log(error);
      setSender({
        ...sended,
        sending: false,
        success: false,
      });
    }
  };

  const handleSenderResCode = async (email, code, token) => {
    try {
      const res = await sendCode(email, code, token);
      const { data } = res;
      setter(user.user);

      cookies.set("::token", data.token, {
        secure: false, //<------------- TRUE for SSL / https
        maxAge: 1440 * 60,
      });
      setSender({
        ...sended,
        sending: false,
        success: true,
      });
      document.location.reload();
    } catch (error) {
      // TODO: Handle error properly
      // console.log(error);
      setSender({
        ...sended,
        sending: false,
        success: false,
      });
    }
  };

  const handleResetPassword = async (event) => {
    event.preventDefault();
    if (email && email.length) {
      setResetPasswordInfo({ ...resetPasswordInfo, loading: true });
      try {
        await ResetPassword(email);
      } catch (error) {
        // TODO: Handle error properly
        // console.log(error);
      }
      setResetPasswordInfo({
        ...resetPasswordInfo,
        loading: false,
        isSent: true,
      });
    }
  };
  if (step === 1) {
    return (
      <>
        <section id="login-body">
          <div className="top-logo">
            <img
              src="/assets/icons/Footer/logo_bouygues.svg"
              alt="logo-topBar"
            />
          </div>
          <div className="form-container">
            <img
              src="/assets/icons/Footer/logo_HR_team.svg"
              alt="logo-input-box"
            />
            <div className="input-box">
              <h2>Welcome</h2>
              <input
                type="text"
                placeholder="EMail"
                name="email"
                onChange={(event) => setEmail(event.target.value)}
              />
              <div className="input-password__container">
                <button onClick={() => setShowPassword(!showPassword)}>
                  <i className={`icon-${showPassword ? "eye-off" : "eye"}`} />
                </button>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  name="password"
                  onChange={(event) => setPassword(event.target.value)}
                />
              </div>
            </div>
            <p className="paragraph-msg-warning">
              Veillez à bien copier/coller votre mot de passe, <br />
              sans espace (ni avant ni après)
            </p>
            {/*<div>
          <input type="checkbox"> Se souvenir de moi</input>
        </div> */}
            <p className="" style={{ textAlign: "right" }}>
              <button
                className="reset-password"
                onClick={() => setOpenModal(true)}
              >
                Mot de passe oublié ?
              </button>
            </p>
            {!sended.success ? (
              <div className="error-popup">
                <h3>ERREUR</h3>
                <p>Veuillez vérifier votre EMail ou votre mot de passe</p>
              </div>
            ) : (
              ""
            )}
            {sended.sending ? (
              <button className="button-login" disabled>
                <div className="loader"></div>
              </button>
            ) : (
              <button
                className="button-login"
                onClick={() => {
                  handleSenderRes(email, password);
                  setSender({ ...sended, sending: true });
                }}
              >
                SE CONNECTER
              </button>
            )}
          </div>
        </section>
        <DefaultModal
          isOpen={openModal}
          setIsOpen={setOpenModal}
          title="Mot de passe oublié"
        >
          {!resetPasswordInfo.isSent ? (
            <form onSubmit={handleResetPassword}>
              <div className="input_container">
                <label form="password">Adresse mail*</label>
                <input
                  type="text"
                  placeholder="E-Mail"
                  name="email"
                  defaultValue={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </div>
              <br />
              {resetPasswordInfo.loading ? (
                <button className="btn btn-small bg-main" disabled>
                  <div className="loader"></div>
                </button>
              ) : (
                <button type="submit" className="btn btn-small bg-main ">
                  Réinitialiser
                </button>
              )}
            </form>
          ) : (
            <div>
              <h1
                style={{ textAlign: "center" }}
                dangerouslySetInnerHTML={{
                  __html: resetPasswordInfo.success_message,
                }}
              ></h1>
              <br />
              <p style={{ textAlign: "center" }}>
                <button
                  onClick={() => {
                    setOpenModal(false);
                    setResetPasswordInfo({
                      loading: false,
                      success_message:
                        "Si vous êtes inscrit sur le Portail RH, vous avez reçu un e-mail avec vos nouveaux identifiants.<br/> <br/> <p style='font-size: 16px;color: grey;'>Pour contacter un administrateur : <a style='font-size: 16px;color: grey;' href='mailto:HRTEAM@bouygues.com'>HRTEAM@bouygues.com</a></p>",
                      isSent: false,
                    });
                  }}
                  className="btn btn-small bg-main "
                >
                  Fermer
                </button>
              </p>
            </div>
          )}
        </DefaultModal>
      </>
    );
  } else if (step === 2) {
    return (
      <section id="login-body">
        <div className="top-logo">
          <img src="/assets/icons/Footer/logo_bouygues.svg" alt="logo-topBar" />
        </div>
        <div className="form-container">
          <div className="input-code-validation">
            <img
              src="/assets/icons/Footer/logo_HR_team.svg"
              alt="logo-input-box"
            />
            <p>
              Un mail vous a été envoyé
              <br />à l'adresse <span>{email}</span>
              <br />
              avec un code de vérification unique.
            </p>
            <ReactCodeInput
              type="number"
              fields={6}
              className="input-code"
              inputStyle={{
                fontFamily: "monospace",
                margin: "4px",
                MozAppearance: "textfield",
                width: "44px",
                borderRadius: "7px",
                fontSize: "14px",
                height: "33px",
                color: "#000",
                textAlign: "center",
              }}
              onChange={(value) => setCode(value)}
            />
            {!sended.success ? (
              <div className="error-popup">
                <h3>ERREUR</h3>
                <p>Mauvais code de vérification unique.</p>
              </div>
            ) : (
              ""
            )}
            {sended.sending ? (
              <button className="button-login" disabled>
                <div className="loader"></div>
              </button>
            ) : (
              <button
                className="button-login"
                onClick={() => {
                  handleSenderResCode(email, code, user.token);
                  setSender({ ...sended, sending: true });
                }}
              >
                VÉRIFIER
              </button>
            )}
          </div>
        </div>
      </section>
    );
  }
}
