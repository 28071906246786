import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";

//:: API imports

import "../Login/Login.css";

export default function Login({ setter }) {
  return (
    <section id="login-body">
      <div className="top-logo">
        <img src="/assets/icons/Footer/logo_bouygues.svg" alt="logo-topBar" />
      </div>
      <div className="form-container">
        <div className="loader"></div>
      </div>
    </section>
  );
}
