import React, { useState, useEffect, useContext } from "react";
import "./Accordion.css";
import "./Filter.css";

import Filter from "./Filter";
import Category from "./Category/Category";
import State from "./State/State";

import { fetchAllBranches } from "../../api/Branches";
import { fetchAllThematics } from "../../api/MainThemesSubThemes";

import permissions from "../../contexts/permission";
import { FiltersContext } from "../../contexts/filters";

function FilterForm({
  published = false,
  category = true,
  publishedDate = true,
  filtersCallback,
  btnOpen,
  edition = false,
}) {
  const preChecked = useContext(FiltersContext);

  const [thematics, setThematics] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [branches, setBranches] = useState([]);

  const [isPublished, setIsPublished] = useState();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [isOfficial, setIsOfficial] = useState();

  const [filters, setFilters] = useState([]);

  // We use the initialFilters to preChecked all the necessary filters by passing them to the <Filter />
  const getInitialFilters = () =>
    Object.keys(preChecked || {})
      .filter((key) => ["MainThemes", "SubThemes", "Branches"].includes(key)) // Keep only MainThemes, SubThemes & Branches Keys
      .reduce((accumulator, key) => {
        const value = preChecked[key] || [];
        const formattedKey =
          key === "MainThemes"
            ? "main_theme"
            : key === "SubThemes"
            ? "sub_theme"
            : key === "Branches"
            ? "branch"
            : "";
        return [...accumulator, ...value.map((v) => `${formattedKey}:${v.id}`)];
      }, []);

  async function getAllThematics() {
    const res = await fetchAllThematics();
    if (Role.some(({ name }) => name === "ADMIN")) {
      setThematics(res);
    } else if (edition) {
      let temp = [];
      AdminOfMainThemes.forEach((elem) => {
        res.forEach((them) => {
          if (elem.id == them.id) {
            temp.push(them);
          }
        });
      });
      setThematics(temp);
    } else {
      setThematics(res);
    }
  }
  async function getAllBranches() {
    setBranches(await fetchAllBranches());
  }

  const { AdminOfSubThemes, AdminOfMainThemes, Role } = useContext(permissions);

  useEffect(() => {
    getAllThematics();
    getAllBranches();
  }, []);

  useEffect(() => {
    filtersCallback([
      ...filters,
      `startDate:${startDate}`,
      `endDate:${endDate}`,
      `isOfficial:${isOfficial}`,
      `isPublished:${isPublished}`,
    ]);
  }, [filters, startDate, endDate, isOfficial, isPublished]);

  return (
    <React.Fragment>
      <div className={`filter__open-container ${btnOpen ? "open-btn" : ""}`}>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className={`filter__open-filter  bg-main-hover ${
            isOpen ? "bg-main text-white" : "btn-fill"
          }`}
        >
          <i className="icon-filter"></i> Filtrer
        </button>
        <Filter
          className={`filter_main_section ${isOpen ? "active" : ""}`}
          initialFilters={getInitialFilters()}
          onChange={setFilters}
        >
          {/* RESET FILTERS BUTTON*/}
          <Filter.ResetButton className="reset_filter_title">
            <p> Réinitialiser les filtres </p>
          </Filter.ResetButton>

          {category && <Category onClick={(value) => setIsOfficial(value)} />}

          {published && <State onClick={(value) => setIsPublished(value)} />}

          {/* CALENDAR SECTION */}
          {publishedDate && (
            <Filter.Dropdown
              render={() => (
                <div className="calendar_selection">
                  <img
                    alt="calendar_icon"
                    src="/assets/icons/CategorieFilter/iconCalendrierFiltre.svg"
                  />
                  <p>Période de publication</p>
                </div>
              )}
            >
              <input
                type="date"
                id="start"
                name="trip-start"
                min="2000-01-01"
                max="2100-12-31"
                value={startDate}
                onChange={(event) => setStartDate(event.target.value)}
              />
              <input
                type="date"
                id="start"
                name="trip-start"
                min="2000-01-01"
                max="2100-12-31"
                value={endDate}
                onChange={(event) => setEndDate(event.target.value)}
              />
            </Filter.Dropdown>
          )}
          {/* THEMATICS SECTION */}
          <Filter.Dropdown
            isOpen={true}
            className="accordion_main"
            render={(isCollapsed) => (
              <div className="accordion_button">
                <p className="accordion_title">Themes</p>
                <span className="stroke" />
                <img
                  className="ArrowIcon"
                  alt="ArrowIcon"
                  src={`/assets/icons/arrow${isCollapsed ? "On" : "Off"}.svg`}
                />
              </div>
            )}
          >
            {thematics
              .sort((a, b) => (a.id > b.id ? 1 : -1))
              .map((thematic, key) => (
                <Filter.Dropdown
                  key={key}
                  className="accordion_section"
                  value={`main_theme:${thematic.id}`}
                  render={(isActive) => (
                    <div className="accordion_top_selector">
                      <input
                        type="checkbox"
                        className="rounded_input"
                        readOnly={false}
                        checked={isActive}
                      />
                      <img
                        src={`/assets/img/icons/${thematic.icon}`}
                        alt={thematic.name}
                      />
                      <p className="label_sub_accordion">{thematic.name}</p>
                      {thematic.SubThemes.length > 0 && (
                        <img
                          className="ArrowIcon"
                          alt="ArrowIcon"
                          src={`/assets/icons/arrow${
                            isActive ? "On" : "Off"
                          }.svg`}
                        />
                      )}
                    </div>
                  )}
                >
                  {/* SUB_THEMATICS INNER SECTION */}
                  {Role.some(({ name }) => name === "ADMIN")
                    ? thematic.SubThemes.sort((a, b) =>
                        a.id > b.id ? 1 : -1
                      ).map((subThematic, key) => (
                        <Filter.Item
                          key={key}
                          className="accordion_section"
                          value={`sub_theme:${subThematic.id}`}
                          render={(isActive) => (
                            <div
                              className="sub_child_input"
                              onClick={() => {
                                let check = document.getElementById(
                                  `sub_themecheck:${subThematic.id}`
                                );
                                if (check.checked === true) {
                                  check.checked = false;
                                } else {
                                  check.checked = true;
                                }
                              }}
                            >
                              <input
                                type="checkbox"
                                id={`sub_themecheck:${subThematic.id}`}
                                className="rounded_input"
                                readOnly={false}
                                defaultChecked={isActive}
                              />
                              <p className="label_sub_accordion">
                                {subThematic.name}
                              </p>
                            </div>
                          )}
                        />
                      ))
                    : thematic.SubThemes.sort((a, b) =>
                        a.id > b.id ? 1 : -1
                      ).map((subThematic, key) => (
                        <div>
                          {AdminOfSubThemes.some(
                            ({ id }) => id === subThematic.id
                          ) && (
                            <Filter.Item
                              key={key}
                              className="accordion_section"
                              value={`sub_theme:${subThematic.id}`}
                              render={(isActive) => (
                                <div className="sub_child_input">
                                  <input
                                    type="checkbox"
                                    className="rounded_input"
                                    readOnly={false}
                                    checked={isActive}
                                  />
                                  <p className="label_sub_accordion">
                                    {subThematic.name}
                                  </p>
                                </div>
                              )}
                            />
                          )}
                        </div>
                      ))}
                </Filter.Dropdown>
              ))}
          </Filter.Dropdown>

          {/* BRANCH SECTION */}
          <Filter.Dropdown
            isOpen={true}
            className="accordion_main"
            render={(isCollapsed) => (
              <div className="accordion_button">
                <p className="accordion_title">Métiers</p>
                <span className="stroke" />
                <img
                  className="ArrowIcon"
                  alt="ArrowIcon"
                  src={`/assets/icons/arrow${isCollapsed ? "On" : "Off"}.svg`}
                />
              </div>
            )}
          >
            {branches.map((job, key) => (
              <Filter.Item
                key={key}
                className="accordion_section"
                value={`branch:${job.id}`}
                render={(isActive) => {
                  return (
                    <div className="accordion_top_selector">
                      <p className="label_sub_accordion">
                        <input
                          type="checkbox"
                          className="rounded_input"
                          readOnly={false}
                          defaultChecked={isActive}
                          checked={isActive}
                        />
                        <p>{job.name}</p>
                      </p>
                    </div>
                  );
                }}
              />
            ))}
          </Filter.Dropdown>
        </Filter>
      </div>
    </React.Fragment>
  );
}

export default FilterForm;
