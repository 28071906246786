import React, { useState, useEffect } from "react";

// components
import CenterLayout from "../../../layouts/CenterLayout";
import HomeForm from "./homeForm";
/* import "../Thematics/thematicsForm.css";
 */
export default function AHome() {
  return (
    <CenterLayout title="Homepage">
      <HomeForm />
    </CenterLayout>
  );
}
