import React, { useState, useEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  BrowserRouter,
} from "react-router-dom";
import Cookies from "universal-cookie";
import { checkMe } from "./api/Login";
import { useIdleTimer } from "react-idle-timer";

//:: Context Import
import { TokenContext } from "./contexts/token";
import { EditMode } from "./contexts/editmode";
import Permissions from "./contexts/permission";
import { HomeModal } from "./contexts/homepopup";

import Loading from "./pages/Loading/";
import NotFound from "./pages/404/";
import Home from "./pages/Home";
import NewsPage from "./pages/News/";
import LinksPage from "./pages/Links/";
import WelcomePage from "./pages/Welcome/";
import Shares from "./pages/Shares";
import Events from "./pages/Events";
import Event from "./pages/Events/Event";
import Subtheme from "./pages/Subtheme";
import Article from "./pages/News/Article";

import MessagesFeed from "./pages/MessagesFeed/MessagesFeed";
import Thematics from "./pages/Thematics";
import Questions from "./pages/Questions";
import Login from "./pages/Login/Login";
import CompleteRegistration from "./pages/Login/CompleteRegistration";
import Account from "./pages/Account/Account";
import Profile from "./pages/Account/Profile";

//:: ADMIN PAGES IMPORTS
import AHome from "./pages/Admin/Home/";
import AShares from "./pages/Admin/Shares/Shares";
import ALinks from "./pages/Admin/Links/Links";
import AWelcome from "./pages/Admin/Welcome/Welcome";
import AEvents from "./pages/Admin/Events/Events";
import ANews from "./pages/Admin/News/News";
import AThematics from "./pages/Admin/Thematics/Thematics";
import ASubThematics from "./pages/Admin/SubThematics/SubThematics";
import Afaq from "./pages/Admin/Faq/";

//:: ADMIN MODIFICATION PAGE IMPORTS
import ALinksForm from "./pages/Admin/Links/LinksForm";
import CenterLayout from "./layouts/CenterLayout";
import MentionsLegals from "./pages/MentionsLegals";
import PolitiqueDonneesPersonnelles from "./pages/PolitiqueDonneesPersonnelles";
import TestPagePWA from "./pages/Test";
import { fetchAPIStatus } from "./api/Check";
import AnnuairePage from "./pages/Annuaire/annuaire.page";
import PhotothequePage from "pages/Admin/Phototheque/phototheque.page";

function App() {
  /* const [token, setToken] = useState(null); */
  const [user, setUser] = useState(null);
  const [editing, setEditing] = useState(false);
  const [loadingCheckMe, setLoadingCheckMe] = useState(true);
  const [isOpen, setIsOpen] = useState(true);
  const cookies = new Cookies();
  const token = cookies.get("::token");

  // Function to clear complete cache data

  const handleOnIdle = () => {
    if (token) {
      cookies.remove("::token", { path: "/" });
      document.location.reload();
    }
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    crossTab: {
      type: "simulate",
    },
    timeout: 7200000, // 2H in milliseconds
    onIdle: handleOnIdle,
    debounce: 500,
  });

  const getUser = async () => {
    try {
      const userInit = await checkMe(token);
      setUser(userInit);
      setLoadingCheckMe(false);
    } catch (error) {
      setLoadingCheckMe(false);
    }
  };

  const checkVersion = async () => {
    const response = await fetchAPIStatus();
    const localVersion = localStorage.getItem("::version");

    // if (process.env.NODE_ENV === "development") return;

    if (localVersion !== response.version) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      console.warn("New version detected, clearing cache");
      localStorage.setItem("::version", response.version);
      window.location.reload();
    }
  };

  useEffect(() => {
    if (localStorage.getItem("::editing") === "true") {
      setEditing(true);
    }
    if (token) {
      getUser();
    } else {
      setLoadingCheckMe(false);
    }
  }, []);

  // CHECK VERSION
  useEffect(() => {
    checkVersion();
  }, []);

  useEffect(() => {
    localStorage.setItem("::editing", editing);
  }, [editing]);

  if (!token || !user) {
    if (loadingCheckMe) {
      return (
        <BrowserRouter>
          <Route path="/" component={Loading} />
        </BrowserRouter>
      );
    }
    return (
      <BrowserRouter>
        <Route
          path="/"
          render={(props) => <Login {...props} setter={setUser} />}
        />
      </BrowserRouter>
    );
  }

  if (user.status === "IN_REGISTRATION") {
    return (
      <BrowserRouter>
        <Route
          path="/"
          render={(props) => (
            <CompleteRegistration {...props} user={user} setter={setUser} />
          )}
        />
      </BrowserRouter>
    );
  }
  return (
    <div className="App">
      <EditMode.Provider value={{ editing, setEditing }}>
        <Permissions.Provider
          value={{
            AdminOfSubThemes: user.AdminOfSubThemes,
            Role: user.Role,
            AdminOfMainThemes: user.AdminOfMainThemes,
          }}
        >
          <TokenContext.Provider
            value={{
              token,
              getTokenFromCookies: () => {
                const cookies = new Cookies();
                return cookies.get("::token");
              },
            }}
          >
            <HomeModal.Provider value={{ isOpen, setIsOpen }}>
              <BrowserRouter>
                <Router>
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/testpwa" component={TestPagePWA} />
                    <Route
                      exact
                      path="/mentions-legales"
                      component={MentionsLegals}
                    />
                    <Route
                      exact
                      path="/politique-donnees-personnelles"
                      component={PolitiqueDonneesPersonnelles}
                    />
                    <Route exact path="/feed" component={MessagesFeed} />
                    <Route
                      exact
                      path="/account"
                      render={(props) => <Account {...props} user={user} />}
                    />
                    <Route exact path="/users/:user_id" component={Profile} />
                    <Route exact path="/shares" component={Shares} />
                    <Route
                      exact
                      path="/thematics/:theme/:subtheme"
                      component={Subtheme}
                    />
                    <Route exact path="/events" component={Events} />
                    <Route exact path="/events/:event_id" component={Event} />
                    <Route exact path="/shares" component={Shares} />
                    <Route
                      exact
                      path="/thematics/:theme"
                      component={Thematics}
                    />
                    <Route
                      exact
                      path="/thematics/:theme/:subtheme"
                      component={Subtheme}
                    />
                    <Route exact path="/news" component={NewsPage} />
                    <Route exact path="/news/:article_id" component={Article} />
                    <Route exact path="/links" component={LinksPage} />
                    <Route exact path="/welcome" component={WelcomePage} />
                    <Route exact path="/annuaire" component={AnnuairePage} />

                    <Route exact path="/account" component={Account} />
                    {editing &&
                      user &&
                      user.Role &&
                      user.Role.some(({ name }) => name === "ADMIN") ? (
                      <React.Fragment>
                        <Route exact path="/admin/" component={AHome} />
                        <Route exact path="/admin/shares" component={AShares} />
                        <Route exact path="/admin/links" component={ALinks} />
                        <Route
                          exact
                          path="/admin/welcome"
                          component={AWelcome}
                        />
                        <Route exact path="/admin/events" component={AEvents} />
                        <Route exact path="/admin/news" component={ANews} />
                        <Route exact path="/faq" component={Afaq} />
                        <Route exact path="/admin/phototheque" component={PhotothequePage} />
                        <Route
                          exact
                          path="/admin/thematics/:theme"
                          component={AThematics}
                        />
                        <Route
                          exact
                          path="/admin/thematics/:theme/:subtheme"
                          component={ASubThematics}
                        />
                        <Route exact path="/admin/links/:id">
                          <ALinks propsEditing={true} />;
                        </Route>
                        <Route exact path="/admin/shares/:id">
                          <AShares propsEditing={true} />;
                        </Route>
                        <Route exact path="/admin/welcome/:id">
                          <AWelcome propsEditing={true} />;
                        </Route>
                        <Route exact path="/admin/events/:id">
                          <AEvents propsEditing={true} />;
                        </Route>
                        <Route exact path="/admin/news/:id">
                          <ANews propsEditing={true} />;
                        </Route>
                        <Route exact path="/account/:id">
                          <Account propsEditing={true} user={user} />;
                        </Route>
                      </React.Fragment>
                    ) : (
                      ((user.AdminOfSubThemes &&
                        user.AdminOfSubThemes.length > 0) ||
                        (user.AdminOfMainThemes &&
                          user.AdminOfMainThemes.length > 0)) && (
                        <React.Fragment>
                          <Route exact path="/admin/" component={Home} />
                          <Route
                            exact
                            path="/admin/shares"
                            component={AShares}
                          />
                          <Route exact path="/admin/links" component={ALinks} />
                          <Route
                            exact
                            path="/admin/welcome"
                            component={WelcomePage}
                          />
                          <Route
                            exact
                            path="/admin/events"
                            component={AEvents}
                          />
                          <Route exact path="/admin/news" component={ANews} />
                          <Route exact path="/faq" component={Questions} />
                          <Route
                            exact
                            path="/admin/thematics/:theme"
                            component={AThematics}
                          />
                          <Route
                            exact
                            path="/admin/thematics/:theme/:subtheme"
                            component={ASubThematics}
                          />
                          <Route exact path="/admin/links/:id">
                            <ALinks propsEditing={true} />;
                          </Route>
                          <Route exact path="/admin/shares/:id">
                            <AShares propsEditing={true} />;
                          </Route>
                          <Route exact path="/admin/welcome/:id">
                            <AWelcome propsEditing={true} />;
                          </Route>
                          <Route exact path="/admin/events/:id">
                            <AEvents propsEditing={true} />;
                          </Route>
                          <Route exact path="/admin/news/:id">
                            <ANews propsEditing={true} />;
                          </Route>
                        </React.Fragment>
                      )
                    )}
                    <Route exact path="/faq" component={Questions} />
                    <Route component={NotFound} />
                  </Switch>
                </Router>
              </BrowserRouter>
            </HomeModal.Provider>
          </TokenContext.Provider>
        </Permissions.Provider>
      </EditMode.Provider>
    </div>
  );
}

export default App;
