import React, { useState, useEffect } from "react";

// import FilterForm from "../../../components/Filter/FilterForm";
// import SharesContent from "../../../components/ContentListShares";
import { Card } from "../../../components/molecules/AdminPanel/index";

import { fetchAllBranches } from "../../../api/Branches";
import { createNewcomers, updateNewcomers } from "../../../api/Newcomers";
import { Link, useParams } from "react-router-dom";
import moment from "moment";

import { useHistory } from "react-router-dom";
import LoadingBar from "../../../components/molecules/Loaders/Bar";
import CropImage from "../../../components/molecules/CropImage";

// import FirstDropdown from "../../../components/TopBar/MobileNavbar/FirstDropdown";

export default function WelcomeForm({ onClick, welcomeProps }) {
  const [branches, setBranches] = useState([]);
  const [firstname, setFirstname] = useState(null);
  const [lastname, setLastname] = useState(null);
  const [job, setJob] = useState(null); //branchId
  const [poste, setPoste] = useState(null); //Job
  const [wingzy, setWingzy] = useState(null);
  const [date, setDate] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [avatarBlob, setAvatarBlob] = useState(null);
  const [sending, setSending] = useState(false);

  const { id } = useParams();
  const history = useHistory();

  async function getAllBranches() {
    setBranches(await fetchAllBranches());
  }

  useEffect(() => {
    getAllBranches();
  }, []);

  useEffect(() => {
    if (welcomeProps) {
      setFirstname(welcomeProps.fullName.split(" ")[0]);
      setLastname(welcomeProps.fullName.split(" ")[1]);
      setPoste(welcomeProps.job);
      setWingzy(welcomeProps.wingzy);
      setDate(welcomeProps.dateIn);
      setJob(welcomeProps.Branch.id);
    }
  }, [welcomeProps]);

  const sendWelcome = async (e) => {
    e.preventDefault();
    if (!firstname || !lastname) {
      return;
    }
    if (id) {
      if (welcomeProps) {
        setSending(true);
        await updateNewcomers(
          id,
          poste,
          wingzy,
          job,
          firstname + " " + lastname,
          date,
          avatar
        );
        history.push("/admin/welcome");
        window.location.reload();
        setSending(false);
      }
    } else {
      setSending(true);
      await createNewcomers(
        poste,
        wingzy,
        job,
        firstname + " " + lastname,
        date,
        avatar
      );
      window.location.reload();
      setSending(false);
    }
    onClick();
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "50px",
        left: "50%",
        transform: "translate(-50%, 0%)",
        zIndex: "999",
        width: "70%",
        height: "calc(100vh - 50px)",
        overflowX: "auto",
      }}
    >
      <Card>
        <Link to="/admin/welcome">
          <a className="back" onClick={onClick}>
            <i className="icon-short-arrow" style={{ fontSize: "9px" }} />
            Retour
          </a>
        </Link>
        <h1 className="title">Ajouter un collaborateur</h1>
        <form className="admin-form">
          <div className="admin-input-box">
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-evenly",
              }}
            >
              <label
                className="upload-style"
                htmlFor="upload-file"
                style={{
                  backgroundImage: `linear-gradient(0deg, #161b4b2b 0%, #161b4b2b 100%), url("${
                    avatarBlob
                      ? avatarBlob
                      : welcomeProps && welcomeProps.avatarUrl
                      ? welcomeProps.avatarUrl.startsWith("/")
                        ? welcomeProps.avatarUrl
                        : `/${welcomeProps.avatarUrl}`
                      : ""
                  }")`,
                  height: "300px",
                  width: "300px",
                }}
              >
                <i className="icon-cloud" style={{ fontSize: "2rem" }} />
                <p className="btn btn-small bg-main">
                  {avatar || (welcomeProps && welcomeProps.avatarUrl)
                    ? "Changer l'avatar"
                    : "Sélectionnez un avatar"}
                </p>
              </label>
              <input
                id="upload-file"
                type="file"
                onChange={(e) => setAvatar(e.target.files[0])}
                name="avatar"
              />
              {avatar ? (
                <CropImage
                  setImage={setAvatar}
                  setImageBlob={setAvatarBlob}
                  src={avatar}
                  ratio={4 / 4}
                />
              ) : (
                ""
              )}
            </div>
            <br />
            <p className="text-center default-p">
              Recommandé: 200px x 200px, 5MO (max)
            </p>
            <br />
            <input
              className="admin-input"
              type="text"
              placeholder="Prénom*"
              value={firstname && firstname}
              onChange={(e) => setFirstname(e.target.value)}
              required
            />
            <input
              className="admin-input"
              type="text"
              placeholder="Nom*"
              onChange={(e) => setLastname(e.target.value)}
              value={lastname && lastname}
              required
            />
            <select
              className="admin-input"
              style={{ backgroundColor: "white" }}
              type="text"
              placeholder="Choisir le métier*"
              onChange={(e) => setJob(e.target.value)}
              value={job && job}
              required
            >
              <option disabled default selected value="">
                Choisir le métier*
              </option>
              {branches.map((branch) => (
                <option value={branch.id}>{branch.name}</option>
              ))}
            </select>
            <input
              className="admin-input"
              type="text"
              placeholder="Job"
              onChange={(e) => setPoste(e.target.value)}
              value={poste && poste}
            />
            {/* <input
              className="admin-input"
              type="text"
              placeholder="Lien Wingzy"
              onChange={(e) => setWingzy(e.target.value)}
              value={wingzy && wingzy}
            /> */}
            <input
              className="admin-input"
              type="date"
              placeholder="Date"
              onChange={(e) => setDate(e.target.value)}
              value={date && moment(date).format("YYYY-MM-DD")}
            />
            {sending ? (
              <button
                className="save-btn"
                style={{ backgroundColor: "#66666b", margin: "20px" }}
                disabled
              >
                <div className="loader"></div>
              </button>
            ) : (
              <button
                className="save-btn"
                style={{ margin: "20px" }}
                onClick={(e) => {
                  sendWelcome(e);
                }}
              >
                Enregistrer
              </button>
            )}
            {/* {sending && <LoadingBar done={false} />} */}
          </div>
        </form>
      </Card>
    </div>
  );
}
