import CenterLayout from "layouts/CenterLayout";
import Content from "./_components/content";
import FilterAnnuaire from "./_components/filter";
import "./annuaire.style.css";
import { useEffect, useState } from "react";
import { sendFilters } from "api/Annuaire/sendFilters";

export default function AnnuairePage() {
  const [mode, setMode] = useState("list");
  const [loading, setLoading] = useState(false);
  const [isEndScroll, setIsEndScroll] = useState(false);
  const [filter, setFilter] = useState({
    entities: [],
    expertises: [],
    geolocation: [],
    branches: [],
    searchbar: "",
  });
  const [filteredUsers, setFilteredUsers] = useState([]);
  const handleReset = () => {
    setFilter({
      entities: [],
      expertises: [],
      geolocation: [],
      branches: [],
      searchbar: "",
    });
  };

  const sendMyFilters = async (scroll = false) => {
    if (loading) return;
    const myNewFilter = {};
    if (filter.entities.length > 0) {
      myNewFilter.entities = filter.entities.map((item) => item.id);
    } else {
      myNewFilter.entities = null;
    }
    if (filter.expertises.length > 0) {
      myNewFilter.expertises = filter.expertises.map((item) => item.id);
    } else {
      myNewFilter.expertises = null;
    }
    if (filter.geolocation.length > 0) {
      myNewFilter.geolocation = filter.geolocation.map(
        (item) => item.display_name
      );
      myNewFilter.geolocation = myNewFilter.geolocation.join(",");
    } else {
      myNewFilter.geolocation = null;
    }
    if (filter.branches.length > 0) {
      myNewFilter.branches = filter.branches.map((item) => item);
    } else {
      myNewFilter.branches = null;
    }
    if (filter.searchbar.length > 0) {
      myNewFilter.searchbar = filter.searchbar;
    } else {
      myNewFilter.searchbar = null;
    }

    if (scroll) {
      myNewFilter.skip = filteredUsers.length;
      myNewFilter.take = 25;
    } else {
      myNewFilter.skip = 0;
      myNewFilter.take = 25;
    }

    setLoading(true);
    const response = await sendFilters(myNewFilter);
    setLoading(false);
    if (scroll) {
      if (response.length > 0) {
        setFilteredUsers([...filteredUsers, ...response]);
      } else {
        setIsEndScroll(true);
      }
    } else {
      setFilteredUsers(response);
    }
    // Put current filter in localStorage to keep it when switch to map
    localStorage.setItem("hraFilter", JSON.stringify(myNewFilter));
  };
  useEffect(() => {
    setIsEndScroll(false);
    sendMyFilters();
  }, [filter]);

  return (
    <CenterLayout title="Contacts" className="Shares_Pages annuaire_pages">
      <div className="annuaire_body_container">
        <FilterAnnuaire
          isMobileOpen={mode === "filter"}
          onClose={() => setMode("list")}
          setFilter={setFilter}
          onReset={handleReset}
          filter={filter}
        />
        <Content
          loading={loading}
          mode={mode}
          setMode={setMode}
          setSearch={(value) =>
            setFilter((prev) => ({ ...prev, searchbar: value }))
          }
          search={filter.searchbar}
          users={filteredUsers}
          onScrollEnd={() => sendMyFilters(true)}
          isEndScroll={isEndScroll}
        />
      </div>
    </CenterLayout>
  );
}
